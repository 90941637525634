import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'

export default function OganizersAssistancePage() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Assistance aux organisateurs événementiels - Events";
        
    }, []);

    return (
        <div className='nc-PageHome3 relative overflow-hidden container mt-11 mb-24 lg:mb-32'>
            <Helmet>
                <meta name="description" content="Obtenez de l'aide et du support pour tous vos besoins d'organisation d'événements sur Events. Trouvez des réponses à vos questions et contactez notre équipe d'assistance dédiée." />
                <meta 
                    name="keywords" 
                    content="assistance aux organisateurs, support, aide, organisation d'événements, Events, questions fréquentes, contacter l'assistance" 
                />
            </Helmet>
            <div className="nc-SectionHowItWork mt-4 border-neutral-200 rounded-2xl border max-w-6xl mx-auto" style={{backgroundColor: 'rgb(91 207 197 / 4%)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="nc-Section-Heading relative flex flex-col mb-10 lg:mb-10 text-neutral-900 dark:text-neutral-50 p-4 px-4 sm:p-6 xl:p-8" style={{textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="text-center w-full max-w-2xl mx-auto">
                        <h2 className="text-3xl md:text-4xl font-semibold">Assistance aux Organisateurs</h2>
                    </div>
                    <span className="mt-2 md:mt-3 font-normal block text-base sm:text-lg text-neutral-500 dark:text-neutral-400 w79">
                        <strong>Avez-vous déjà publié votre événement sur Events ? </strong><br /><br /> Pour toutes questions ou suggestions spécifiques à votre événement, notre équipe d'assistance est à votre disposition. Veuillez nous contacter avec l'adresse e-mail utilisée pour enregistrer votre événement en précisant l'Objet et aussi le code evenementiel.
                    </span>
                    <Link to={"mailto:support.organizers@app-events.com"} className="nc-Button relative h-auto inline-flex items-center justify-center transition-colors text-sm sm:text-base lg:text-lg font-bold px-6 py-3 lg:px-8 lg:py-2 mt-10 rounded-xl ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 focus:outline-none focus:ring-offset-2 dark:focus:ring-offset-0">
                        Contactez le Support
                        <svg className='font-bold ml-2 text-sm sm:text-base lg:text-lg'
                            style={{transform: 'rotate(-45deg)', }}
                            width={14}
                            height={14}
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M1.00098 7H13.001"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.00098 1L13.001 7L7.00098 13"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    )
}
