import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {

    const [isVisible, setIsVisible] = useState(false);

    const handleLinkClick = () => {
      setIsVisible(false); // Masquer le contenu lorsque le lien est cliqué
    };
  
    const toggleVisibility = () => {
      setIsVisible(prevState => !prevState);
    };

    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    return (
        <>
            <div className='fixed right-3 z-40 flex items-center' style={{top: '80%'}}>
                {isVisible && (
                    <div className="absolute sm:right-2 right-0 sm:left-0 z-10 mt-3 w-screen max-w-sm" style={{bottom: '3rem'}}>
                        <div className="rounded-2xl bg-white dark:bg-neutral-800 overflow-hidden nc-custom-shadow-1" style={{marginLeft: '20px',}}>
                            <div className="relative p-4">
                            <span className="text-xl font-semibold">Newsletter Events</span>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mt-2" />
                            <div className="mt-4" style={{fontSize: '15px'}}>
                                Recevez les dernières nouvelles et offres exclusives d'Events directement dans votre boîte mail. Ne manquez aucun événement !
                            </div>
                            
                            </div>
                            <div className="bg-gray-50 dark:bg-white/5 p-5">
                            <Link
                                className="flex items-center justify-center w-full px-4 py-2 !rounded-xl text-sm font-medium bg-primary-6000 text-white hover:bg-primary-700"
                                to="/events-App/newsletter-inscription/r/"
                                rel="noopener noreferrer"
                                onClick={handleLinkClick}
                            >
                                <span className="ml-2">Inscrivez-vous maintenant</span>
                            </Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="relative">
                    <button
                        className="text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center"
                        type="button"
                        onClick={toggleVisibility}
                        aria-expanded={isVisible}
                        style={{backgroundColor: 'var(--primary)',color: '#fff'}}
                    >
                        <i className="las la-envelope" /> 
                    </button>
                    
                </div>
            </div>
            <div className="nc-Footer relative py-14 pb-3 lg:py-16 border-t border-neutral-200 dark:border-neutral-700">
                <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 mb-6">
                    <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                        <div className="col-span-2 md:col-span-1">
                            <Link
                                className="ttnc-logo ncSectionLogos inline-block text-primary-6000 focus:outline-none focus:ring-0 w-24"
                                to="/">
                                <img
                                style={{width : '120px'}}
                                    src={`${imagePath}images/logo.png`}
                                    alt="Logo Events"
                                />
                            </Link>
                        </div>
                        <div className="col-span-2 flex items-center md:col-span-3">
                            <div
                                className="nc-SocialsList1 flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start"
                                data-nc-id="SocialsList1"
                            >
                                <Link
                                    to="https://www.facebook.com/profile.php?id=61558762937775" target='_blank' title="Facebook" rel="noopener noreferrer"
                                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                                >
                                    <i className="lab la-facebook-square" />
                                    <span className="hidden lg:block text-sm">Events Facebook</span>
                                </Link>
                                {/* <Link
                                    to="https://www.twitter.com/"
                                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                                >
                                    <i className="lab la-twitter" />
                                    <span className="hidden lg:block text-sm">Twitter</span>
                                </Link>
                                <Link
                                    to="https://www.youtube.com/"
                                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                                >
                                    <i className="lab la-youtube" />
                                    <span className="hidden lg:block text-sm">Youtube</span>
                                </Link> */}
                                <Link
                                    to="https://www.instagram.com/events.app225?igsh=YzljYTk1ODg3Zg==" target='_blank' title="Instagram" rel="noopener noreferrer"
                                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                                >
                                    <i className="lab la-instagram" />
                                    <span className="hidden lg:block text-sm">Events Instagram</span>
                                </Link>
                                <Link
                                    to="https://wa.me/+2250717519518" target='_blank' title="Whatsapp" rel="noopener noreferrer"
                                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                                >
                                    <i className="lab la-whatsapp" />
                                    <span className="hidden lg:block text-sm">Events Support Whatsapp</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="text-sm">
                        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                            Pages
                        </h2>
                        <ul className="mt-5 space-y-4">
                            <li>
                                <Link
                                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    to="/a-propos-de-l'appli-events"
                                >
                                    A Propos
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    to="/nous-contacter"
                                >
                                    Nous contacter
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    to="/Events-usedFolder/FAQs-events"
                                >
                                    FAQs
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="text-sm">
                        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                            Ressources
                        </h2>
                        <ul className="mt-5 space-y-4">
                            <li>
                                <Link
                                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    to="/Events-usedFolder/conditions-generales-utilisations"
                                >
                                    Conditions Générales
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    to="/Events-usedFolder/politique-confidentielle"
                                >
                                    Politique de confidentialité
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    to="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6"
                                >
                                    Charte des organisateurs
                                </Link>
                            </li>
                        </ul>

                    </div>

                    <div className="text-sm">
                        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                            Obtenir l'application
                        </h2>
                        <div className="mt-5 space-y-4">
                            <Link to="#" className="store leng" style={{ width: "100%" }}>
                                <img
                                    style={{ height: "revert-layer" }}
                                    className="appstore-original"
                                    src={`${imagePath}images/btn-android.webp`}
                                    alt="Android logo"
                                />
                            </Link>
                            <Link to="#" className="store leng" style={{ width: "100%" }}>
                                <img
                                    style={{ height: "revert-layer" }}
                                    className="appstore-original"
                                    src={`${imagePath}images/btn-ios.webp`}
                                    alt="Android logo" 
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="text-sm text-center">
                    <hr className='mb-5' />
                    <span className='font-bold text-neutral-6000 dark:text-neutral-300'>
                        Copyright &copy; 2022 App-Events. Tous droits réservés. | Conçu et développé par <span style={{ color: 'var(--primary)', }}>App Events</span>
                    </span>
                </div>
            </div>
        </>
    )
}
