import React, { useEffect, useState } from 'react'
import axios from 'axios';
import RecapFirstContentTab from '../../components/OrganizersFoldersComponents/RecapFirstContentTab';
import SalesSecondContentTab from '../../components/OrganizersFoldersComponents/SalesSecondContentTab';
import { Helmet } from 'react-helmet';

export default function ConsultationsHomePage() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Consultations des demandes événementielles - Events";
        
    }, []);

    const [searchCode, setSearchCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [eventData, setEventData] = useState(null);
    const [responseDataSales, setResponseDataSales] = useState([]);
    const [responseDataActivities, setResponseDataActivities] = useState([]);
    const [eventDataNotFound, setEventDataNotFound] = useState(null);
    // État pour suivre l'onglet actif
    const [activeTab, setActiveTab] = useState(0);

    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    const handleChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Supprime tout ce qui n'est pas un chiffre

        // Formate la chaîne avec le tiret après 14 chiffres
        let formattedValue = value;
        if (value.length > 14) {
            formattedValue = value.slice(0, 14) + '-' + value.slice(14, 18);
        }

        setSearchCode(formattedValue);
        
        // Validation en cours de saisie
        if (formattedValue.length === 19) {
            setErrorMessage('');
        } else {
            setErrorMessage('Le code doit contenir exactement 18 chiffres et un tiret.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (searchCode.length !== 19) { // 14 chiffres + '-' + 4 chiffres
            setErrorMessage('Erreur! Le code doit contenir exactement 18 caractères.');
            return;
        }

        // Traitez la soumission du formulaire ici
        const fullCode = `EVENTS-r${searchCode}`;

        setIsLoading(true);
        setEventData(null); // Reset event data
        setEventDataNotFound(null); // Reset error message
        // 
        try {
            axios.get("https://backoffice.app-events.com/api/secureRoot/events/EventsRequestSent/get32-544-r"+fullCode+"/recupRequestInformations-3-54", {
                
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {

                // Mise à jour du statut selon la réponse du serveur
                const dataFetch = response.data;
                setEventData(dataFetch.responseData);
                setResponseDataSales(dataFetch.responseDataSales);
                setResponseDataActivities(dataFetch.responseDataActivities);
    
                // Fermer le lading
                setIsLoading(false);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 404) {
                        setEventDataNotFound(error.response.data.errors); //Section de demande introuvable
                        setIsLoading(false);

                    } else {
                        
                        setEventDataNotFound("OUPS ! Une erreur est survenue, Veuillez réessayer ultérieurement.");
                        setIsLoading(false);
                    }
                } else if (error.request) {
                    setErrorMessage("Pas de réponse du serveur. Vérifiez votre connexion réseau.");
                    setIsLoading(false);

                    // Fermer le message
                    setTimeout(() => setErrorMessage(""), 4000);
                }
    
            });
        } catch (err) {
            setErrorMessage("Erreur de traitement ! Veuillez réessayer s'il vous plait !");

            // Fermer le loading
            setIsLoading(false);

            // Fermer le message
            setTimeout(() => setErrorMessage(""), 4000);
        }

    };

    // Tableau de contenu des onglets
    const tabContent = [(
        <div key={0}>
            {/* Contenu du premier onglet */}
            <RecapFirstContentTab title="Récapitulatif" eventData={eventData} responseDataActivities={responseDataActivities} />
        </div>
        ),
        (
        <div key={1}>
            
            {/* // Contenu du deuxième onglet */}
            <SalesSecondContentTab title="Ventes" eventData={eventData} responseDataSales={responseDataSales} />
        </div>
        )
    ];

    return (
        <div className='nc-PageHome3 relative overflow-hidden'>
            <Helmet>
                <meta name="description" content="Consultez et gérez facilement vos événements publiés sur Events. Accédez à des rapports détaillés, suivez les ventes de billets, et optimisez la promotion de vos événements pour maximiser votre succès." />
                <meta 
                    name="keywords" 
                    content="consultation des événements, gestion des événements, rapports de ventes, suivi des billets, promotion d'événements, Events" 
                />
            </Helmet>
            <div className="nc-SectionHowItWork mt-14" style={{marginBottom: '5rem'}}>
                <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-center justify-between mb-10 lg:mb-10 p-4 px-4 sm:p-6 text-neutral-900 dark:text-neutral-50" style={{textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="text-center w-full max-w-2xl mx-auto">
                        <h2 className="text-3xl md:text-4xl font-semibold">Consultez votre Événement</h2>
                    </div>
                    <span className="mt-2 md:mt-3 font-normal block text-base sm:text-lg text-neutral-500 dark:text-neutral-400 w79">
                        Entrez le code de votre demande de publication pour accéder aux détails et suivre l'avancement de votre événement. Notre plateforme vous offre une transparence totale et une gestion simplifiée de vos événements publiés. Restez informé et assurez-vous que tout se déroule comme prévu.
                    </span>
                    <span className="mt-6 block w-8 rounded-full border border-neutral-400" style={{top: '10.5rem'}} />
                </div>
                <div className="max-w-screen-md mx-auto pt-5 disk-max-query-m">
                    <form onSubmit={handleSubmit} className="w-full relative flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 md:h-16" style={{backgroundColor: 'rgb(91 207 197 / 5%)'}}>
                        <div className="relative flex flex-[1.5]">
                            <div className="gr-pr flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 focus:outline-none text-left  ">
                            
                                <div className="flex-grow relative">
                                    <div className="gr-pl absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-2xl font-bold disk-max-query-font-s">EVENTS-r</span>
                                    </div>
                                    <input
                                        className="block disk-max-query-font-s disk-max-query-font-m w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 text-2xl font-bold placeholder-neutral-800 dark:placeholder-neutral-200 truncate" style={{paddingLeft: '8rem'}}
                                        placeholder="..."
                                        type="text"
                                        required
                                        name="searchCode"
                                        value={searchCode}
                                        onChange={handleChange}
                                        maxLength="19"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center focus:outline-none ">
                        
                            <div className="pr-2 xl:pr-4">
                                <button
                                onClick={handleSubmit}
                                type="submit"
                                className="disk-max-query-w-h h-14 md:h-12 w-full md:w-12 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                    {errorMessage && (
                        <div className="px-2.5 py-1 p-3 mt-8 flex nc-Badge rounded-full font-medium text-xs text-red-800 bg-red-100 items-center mx-auto"style={{width: "fit-content"}}>
                            <span>
                                <svg
                                viewBox="0 0 24 24"
                                width={20}
                                height={20}
                                stroke="currentColor"
                                strokeWidth={2}
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="me-2"
                                >
                                <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                                <line x1={15} y1={9} x2={9} y2={15} />
                                <line x1={9} y1={9} x2={15} y2={15} />
                                </svg>
                            </span>
                            <span className="text-sm ml-3 mr-3">{errorMessage}</span>
                        </div>
                    )}
                </div>

            </div>
            
            {eventData && !isLoading && (
                // {/* Content for responses */}
                <div className='relative overflow-hidden container mt-11 mb-24 lg:mb-32'>
                    <div className="mt-4 border-neutral-200 rounded-2xl border max-w-6xl mx-auto">
                        <div className="nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group p-4 px-4 sm:p-6 xl:p-8">
                            <div className="flex flex-col flex-grow">
                                <div className="space-y-5 mb-4">
                                <div className="nc-CategoryBadgeList flex flex-wrap space-x-2 inline-flex px-2.5 py-1 rounded-full font-medium text-sm relative text-blue-800 bg-blue-100">
                                    {eventData.request_identity} 
                                </div>
                                <div>
                                    <h2 className="block font-semibold text-neutral-900 dark:text-neutral-100 text-2xl line-clamp-2" title={eventData.titre}>
                                    {eventData.titre}
                                    </h2>
                                    <div className="hidden sm:block sm:mt-2">
                                        <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-2">
                                            {eventData.description}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="nc-PostCardMeta inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 text-sm leading-none"
                                    data-nc-id="PostCardMeta"
                                >
                                    <div className="flex-shrink-0 relative flex items-center space-x-2">
                                        <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-7 w-7 text-sm ring-1 ring-white dark:ring-neutral-900">
                                            <img
                                            className="absolute inset-0 w-full h-full object-cover rounded-full"
                                            src={`${imagePath}images/666201.png`}
                                            alt={eventData.author}
                                            />
                                        </div>
                                        <span className="block text-neutral-6000 dark:text-neutral-300 dark:hover:text-white font-medium">
                                        {eventData.author}
                                        </span>
                                    </div>
                                    <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-bold" style={{fontSize: '1.25rem'}}> · </span>
                                    <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                                    Publiée le {eventData.date_publication}
                                    </span>
                                </div>
                                </div>
                            </div>
                            <div className="block flex-shrink-0 sm:w-56 sm:ml-6 rounded-xl overflow-hidden mb-5 sm:mb-0">
                                <div className="block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16 ">
                                    <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                                        <img
                                        src={`data:image/jpeg;base64,${eventData.banner}`}
                                        className="object-cover w-full h-full"
                                        alt={eventData.titre}
                                        />
                                    </div>
                                    <span>
                                        <div className="nc-PostTypeFeaturedIcon absolute left-2 bottom-2"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Content two */}
                    <div
                        className="flex p-1 space-x-1 bg-neutral-200 rounded-xl mt-5 max-w-screen-md mx-auto mb-5"
                        style={{ maxWidth: 578 }} >
                        <button
                            className={`w-full py-2.5 text-sm leading-5 font-medium text-dark-700 ${activeTab === 0 ? 'bg-white dark:text-dark-700 font-semibold' : 'dark:text-dark-400'} rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 shadow`}
                            onClick={() => setActiveTab(0)}
                            aria-selected={activeTab === 0}
                            tabIndex={activeTab === 0 ? 0 : -1}
                            role="tab"
                            type="button"
                        >
                            Mes Validations
                        </button>
                        <button
                            className={`w-full py-2.5 text-sm leading-5 font-medium text-dark-700 ${activeTab === 1 ? 'bg-white dark:text-dark-700 font-semibold' : 'dark:text-dark-400'} rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 shadow`}
                            onClick={() => setActiveTab(1)}
                            aria-selected={activeTab === 1}
                            tabIndex={activeTab === 1 ? 0 : -1}
                            role="tab"
                            type="button"
                        >
                            Mes Activités De Ventes
                        </button>
                    </div>
                    
                    {/* Contenu de l'onglet actif */}
                    {tabContent[activeTab]}

                </div>
            )}

            {/* Event Not found */}
            {eventDataNotFound && !isLoading && (
                <div className="text-center max-w-2xl mx-auto space-y-2 mb-16 p-4 px-4 sm:p-6">
                    <div className="nc-NcImage flex justify-center">
                        <img
                        src={`${imagePath}images/not-found.webp`}
                        className="lg:w-3/5"
                        alt="Request Not Found"
                        />
                    </div>
                    <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 font-medium">
                        {eventDataNotFound}
                    </span>
                </div>
            )}

            {isLoading && 
                <div className="flex p-1 space-x-1 mt-5 max-w-screen-md mx-auto mb-5 items-center justify-center">
                    <svg
                        className="animate-spin -ml-1 mr-3 sm:h-9 w-9"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        >
                        <circle
                            className="opacity-25"
                            cx={12}
                            cy={12}
                            r={10}
                            stroke="currentColor"
                            strokeWidth={3}
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                    </svg>

                </div>
            }
        </div>
    )
}
