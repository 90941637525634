import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import FAQItem from './FAQItem'; // Importe le composant FAQItem
import FAQItemOrganizer from './FAQItemOrganizer';
import { Helmet } from 'react-helmet';

export default function FAQs() {

    const location = useLocation();
    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "FAQ - Events";
        
        // Vérifier les paramètres de recherche dans l'URL
        const searchParams = new URLSearchParams(location.search);
        const tab = searchParams.get('tab');
        if (tab) {
            setActiveTab(tab);
        }
    }, [location]);

    const [activeTab, setActiveTab] = useState('user'); // 'user' par défaut, car c'est l'onglet actif par défaut
    const [visibleFAQs, setVisibleFAQs] = useState({
        user: 5, // Nombre initial de questions visibles pour la section utilisateur
        organizer: 5, // Nombre initial de questions visibles pour la section organisateur
    });

    const faqsUser = [
        {
            question: "Comment installer l'application 'Events' sur mon mobile ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>Pour installer l'application <b>Events</b> sur votre mobile, suivez ces étapes :</p>
                    <ul>
                        <li>Rendez-vous sur l'<Link className="font-medium text-primary-6000" to="##">App Store</Link> (pour les utilisateurs iOS) ou sur le <Link className="font-medium text-primary-6000" to="##">Google Play Store</Link> (pour les utilisateurs Android).</li>
                        <li>Recherchez l'application "Events" et téléchargez-la sur votre appareil.</li>
                    </ul>
                    <p>Une fois le téléchargement terminé, installez l'application et ouvrez-la.</p>
                </div>
            ),
        },
        {
            question: "Comment puis-je créer un compte sur l'application 'Events' ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>Pour créer un compte, cliquez sur le bouton "Créer un compte" ou "S'inscrire" sur l'écran d'accueil de l'application et suivez les instructions. Vous devrez fournir quelques informations de base, telles que votre nom, votre adresse e-mail et choisissez un mot de passe sécurisé. etc...</p>
                </div>
            )
        },
        {
            question: "Comment puis-je réserver des billets pour un événement ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Une fois connecté à votre compte, parcourez les événements disponibles. 
                        Cliquez sur l'événement qui vous intéresse ou sur "réserver", sélectionnez le type de billet et suivez 
                        les étapes pour finaliser la réservation.
                        Une fois la réservation validée, vous recevrez un e-mail de confirmation.
                    </p>
                </div>
            )
        },
        {
            question: "Quelle est ma preuve d'achat ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Une fois la réservation validée, vous recevrez les e-tickets automatiquements, qui seront également enregistrés en toute sécurité à votre nom dans votre application dans l'onglet "Mes Tickets".
                        Ces e-tickets (QR Code) confirme votre achat en plus du mail de confirmation de réservation.
                    </p>
                </div>
            )
        },
        {
            question: "Quels types de paiement sont acceptés ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Avec <b>Events</b> nous acceptons tous les moyens de paiements uniquement en ligne (Mobile money, Wave ou les paiements par carte de crédit/débit). 
                        Assurez-vous d'utiliser une méthode de paiement sécurisée et légale.
                    </p>
                </div>
            )
        },
        {
            question: "Où puis-je trouver mes billets après achat ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Vos billets seront disponibles dans la section "Mes Tickets" de l'application. Vous pourrez les afficher, les télécharger ou les partager depuis cette section.
                    </p>
                </div>
            )  
        },
        {
            question: "Que faire si j'ai oublié mon mot de passe ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Sur l'écran de connexion, cliquez sur "Mot de Passe Oublié" et suivez les instructions pour réinitialiser votre mot de passe.
                    </p>
                </div>
            )
        },
        {
            question: "Comment puis-je signaler un problème technique ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Utilisez la section "Support Technique" dans les paramètres de l'application pour signaler tout problème technique. Décrivez le problème aussi précisément que possible.
                    </p>
                </div>
            )
        },
        {
            question: "Quels sont les avantages de créer un compte 'Events' ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Créer un compte sur l'application 'Events' vous offre de nombreux avantages pour améliorer 
                        votre expérience utilisateur et simplifier votre participation à divers événements. <br />
                        Réservez vos billets en quelques clics. L'application simplifie le processus d'achat 
                        et de réservation, rendant l'expérience utilisateur fluide et agréable. 
                        Fini les files d'attente fastidieuse la solution de réservation simple et sécurise est maintenant disponible gratuitement pour vous.
                    </p>
                </div>
            )
        },
        {
            question: "Puis-je changer d’adresse email pour mon compte ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Oui, vous pouvez changer l'adresse email associée à votre compte 'Events'.
                        Connectez-vous à votre compte, accédez à la section "Profil" pour modifier votre adresse email, et suivez les instructions de validation. 
                    </p>
                </div>
            )
        },
        {
            question: "Evénement annulé : Comment faire ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Le ticket de l'événement que vous avez réservé a été annulé par l'organisateur. <b>Events</b> se charge de rembourser aux clients pour les annulations d'événement.
                        Pour celà il vous faut envoyer une demande de remboursement à partie de l'événement en question.
                    </p>
                </div>
            )
        },
        {
            question: "Vous ne rétrouvez pas un événement sur l'application ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Si vous ne trouvez pas l'événement sur l'appli <b>Events</b> assurez-vous que l'organisateur de l'événement l'a bien enregistré. Si tel est le cas, 
                        alors l'événement peut être invisible pour plusieurs raisons : <b>déjà terminé ou annulé,</b> <b>événement restreint</b> pour violation des conditions ou <b>En attende de validation par Events</b>, etc.
                    </p>
                </div>
            )
        },
        {
            question: "Comment nous garantissons des événements de qualité ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Les événements publiés sur l'application <b>Events</b> de part des organisateurs partenaires de <b>Events</b> font l'objet de vérifications minutieuses pour vous permettre de réserver en toute serenité. 
                        Cela permet à <b>Events</b> de fournir des services de qualité à ses clients.
                    </p>
                </div>
            )
        },
        {
            question: "Puis-je annuler ma réservation après l'achat d'un billet sur Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Non, il n'est pas possible d'annuler une réservation après l'achat d'un billet sur <b>Events</b>. 
                        Cette politique est clairement définie dans nos <Link className="font-medium text-primary-6000" to="/Events-usedFolder/conditions-generales-utilisations">conditions générales d'utilisation </Link> 
                        que vous avez acceptés lors de votre inscription. 
                        Nous vous recommandons de vérifier attentivement tous les détails de l'événement avant de 
                        finaliser votre achat. Pour toute assistance supplémentaire, n'hésitez pas à contacter notre <Link className="font-medium text-primary-6000" to="mailto:contact@app-events.com">support client</Link>.
                    </p>
                </div>
            )
        },
        {
            question: "Quelle est la politique de remboursement d'Events en cas d'annulation de l'événement ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        En cas d'annulation d'un événement par l'organisateur, Events s'engage à rembourser jusqu'à 80% du prix du billet acheté et valable. 
                        Vous serez notifié de l'annulation par email, et le remboursement sera effectué sur le mode de paiement initial utilisé pour l'achat du billet. 
                        Pour plus de détails, vous pouvez consulter la section dédiée à la politique de remboursement dans les <Link className="font-medium text-primary-6000" to="/Events-usedFolder/conditions-generales-utilisations">conditions générales d'utilisation </Link> sur notre site.
                    </p>
                </div>
            )
        },
        {
            question: "Comment Events assure-t-il la sécurité des paiements en ligne ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Events prend la sécurité des paiements en ligne très au sérieux. Nous utilisons des technologies de 
                        cryptage avancées pour protéger vos informations de paiement. Tous les paiements effectués via notre 
                        plateforme sont traités par des prestataires de services de paiement certifiés comme : Orange Money, MTN Momo, Wave, (etc...), garantissant ainsi la 
                        confidentialité et la sécurité de vos données financières. 
                        De plus, nous surveillons constamment notre système pour détecter et prévenir toute activité frauduleuse.
                    </p>
                </div>
            )
        },
        {
            question: "Le jour de l'événement, comment accéder à la salle ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>Le jour de l'événement, voici les étapes à suivre pour garantir une expérience fluide et agréable :</p>
                    <ul>
                        <li><b>Arrivée à l'événement :</b> Présentez-vous à l'entrée de l'événement avec une pièce d'identité valide. Votre réservation est enregistrée sous votre nom et peut être vérifiée à l'entrée.</li>
                        <li><b>Présentation du QR code :</b> Ouvrez votre application Events et accédez à votre billet électronique. Présentez le code QR associé à votre billet à l'entrée pour qu'il soit scanné par le personnel de l'événement. Ce code QR est essentiel pour valider votre entrée.</li>
                    </ul>
                    <p>
                        Une fois votre billet vérifié, vous pourrez entrer et profiter de l'événement.
                        Si vous avez des questions ou des besoins particuliers le jour de l'événement, n'hésitez pas à contacter le personnel de l'événement ou à utiliser l'application 'Events' pour obtenir de l'aide. <br /><br />
                        En suivant ces étapes, vous assurerez une entrée rapide et sans tracas à l'événement. N'oubliez pas que tous les détails de votre réservation, y compris le billet électronique et le QR code, sont disponibles dans votre application Events.
                    </p>
                </div>
            )
        },
        {
            question: "Comment puis-je partager l'application Events avec d'autres personnes ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Vous pouvez facilement partager l'application Events avec vos amis, 
                        votre famille et vos collègues en utilisant les fonctionnalités de partage intégrées.
                        Pour cela, ouvrez l'application 'Events', accédez à la section "Mon Profil" puis dans "Paramètres" et cliquez sur "Partager" 
                        pour choisir la méthode de partage qui vous convient le mieux, comme l'envoi par e-mail, SMS, ou via les réseaux sociaux, etc... En partageant l'application, vous permettez à plus de personnes de découvrir et de profiter des événements passionnants disponibles sur <b>Events</b>.
                    </p>
                    
                </div>
            )
        },
        {
            question: "Comment puis-je contacter l'assistance pour obtenir de l'aide ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Si vous avez besoin d'assistance, vous pouvez contacter notre équipe de support de plusieurs 
                        façons. Vous pouvez nous envoyer un e-mail à <Link className="font-medium text-primary-6000" to="mailto:contact@app-events.com">contact@app-events.com</Link>, 
                        utiliser le formulaire de <Link to="/nous-contacter" className="font-medium text-primary-6000">contact</Link> sur notre site web, ou 
                        accéder à la section "Support" dans l'application Events. Nous sommes disponibles pour répondre à toutes vos questions et vous aider 
                        avec toute préoccupation concernant vos réservations, remboursements ou toute autre question relative à l'application Events. 
                        Assurez-vous d'utiliser l'adresse e-mail enregistrée lors de votre inscription pour une assistance rapide et efficace.
                    </p>
                    
                </div>
            )
        },
    ];

    const faqsOrganizer = [
        {
            question: "Comment puis-je publier mon événement sur le site officiel de Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Avec <b>Events</b>, la publication d'un événement est simple et rapide. Vous n'avez pas besoin de créer un compte organisateur séparément. 
                        Rendez-vous sur le site officiel et cliquez sur "Publier mon événement". Vous serez automatiquement dirigé vers la section de création 
                        de compte organisateur où vous pourrez enregistrer votre événement. Suivez les étapes indiquées et en quelques clics, votre événement 
                        sera prêt à être publié. 
                        C'est un processus fluide et efficace qui vous permet de concentrer sur l'essentiel : la réussite de votre événement
                    </p>
                </div>
            ),
        },
        {
            question: "Quels types d'événements puis-je publier sur Events ?",
            answer:
                "Events accepte une variété d'événements, tels que des concerts, des conférences, des spectacles, des festivals, et bien plus encore. Assurez-vous de respecter nos directives d'utilisation et les normes de contenu.",
        },
        {
            question: "Que se passe-t-il après la publication d'un événement sur le site officiel de Events avant qu'il soit disponible sur l'application mobile ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Après avoir publié votre événement sur le site officiel de <b>Events</b>, plusieurs étapes de validation sont effectuées avant que l'événement soit disponible sur l'application mobile :
                    </p>
                    <ul>
                        <li>
                            <b>Validation des Informations de l'Événement :</b> Les informations que vous avez fournies pour l'événement sont vérifiées pour s'assurer qu'elles sont complètes et conformes aux normes de qualité de <b>Events</b>.
                        </li>
                        <li>
                            <b>Validation des Informations du Profil de l'Organisateur :</b> Votre profil d'organisateur est examiné pour garantir que toutes les informations sont correctes et à jour.
                        </li>
                        <li>
                            <b>Demande d'Informations Supplémentaires :</b> <b>Events</b> vous contactera pour vous demander de soumettre d'autres informations importantes et confidentielles nécessaires à la validation finale de votre événement.
                        </li>
                        <li>
                            <b>Signature du Contrat et Acceptation des Conditions :</b> Vous devez signer le contrat et accepter les conditions d'utilisation et la <Link className="font-medium text-primary-6000" to="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6">Charte des organisateurs</Link> de <b>Events</b>. Cette étape formalise votre engagement à respecter les règles et conditions de la plateforme.
                        </li>
                        <li>
                            <b>Préparation de l'Événement pour Publication :</b> Une fois les validations terminées, votre événement est préparé pour être publié sur l'application mobile. Cela inclut la création de visuels promotionnels et la mise en place de la page de l'événement sur l'application.
                        </li>
                        <li>
                            <b>Publication de l'Événement :</b> Enfin, votre événement est publié sur l'application mobile de 'Events', rendant les billets disponibles à l'achat pour les utilisateurs de l'application.
                        </li>
                    </ul>
                    <p>Ces étapes permettent de garantir que chaque événement publié sur <b>Events</b> est de haute qualité et offre une expérience optimale aux participants. Vous pouvez suivre l'état de votre événement et recevoir des notifications à chaque étape du processus.</p>
                </div>
            ),
        },
        {
            question: "Combien de temps faut-il pour que mon événement soit publié officiellement sur l'application Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Une fois que vous avez soumis votre événement sur le site officiel de Events, plusieurs étapes de validation et 
                        de préparation sont nécessaires avant sa publication officielle sur l'application mobile.
                        <br /><br />
                        En général, le processus complet peut prendre jusqu'à 48 heures, en fonction de la rapidité de la soumission des informations 
                        supplémentaires et de la signature du contrat par l'organisateur. Events s'efforce de traiter 
                        chaque demande rapidement et de manière efficace pour assurer une publication rapide et sans accroc de votre événement.
                        Vous serez notifié par e-mail une fois que votre événement sera approuvé et publié.
                    </p>
                </div>
            ),
        },
        {
            question: "Quelles informations dois-je fournir lors de la création de mon événement ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Assurez-vous de fournir des informations complètes, y compris le nom de l'événement, la catégorie, la date, l'heure, le lieu, une description détaillée, et des images de qualité. Plus vos informations sont détaillées, plus votre événement est susceptible d'attirer des participants
                    </p>
                </div>
            ),
        },
        {
            question: "Y a-t-il des frais pour publier mon événement sur Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        La publication d'événements sur le site officiel de <b>Events</b> est gratuite.
                        Les frais applicables aux organisateurs pour la publication d'un événement sur <b>Events</b> sont spécifiés dans la <Link className="font-medium text-primary-6000" to="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6">Charte des organisateurs</Link>.
                        Ces frais couvrent les coûts de mise en ligne, de promotion, et de gestion de l'événement sur notre plateforme. Pour des détails précis concernant les frais, veuillez consulter la section dédiée dans le contrat ou contacter notre <Link className="font-medium text-primary-6000" to="/nous-contacter">support d'aide</Link> pour toute clarification supplémentaire.
                    </p>
                </div>
            ),
        },
        {
            question: "Puis-je modifier les détails de mon événement après l'avoir publié ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Si vous avez besoin de modifier les informations de votre événement après l'avoir soumis, veuillez contacter notre <Link className="font-medium text-primary-6000" to="/Organisateurs/AssistanceEvents/contact-assisance-organisateurs">équipe de support </Link> 
                        dès que possible en précisant le code de l'événement en question. Nous vous guiderons sur les étapes à suivre et les conditions applicables pour effectuer ces 
                        modifications. Notez que certaines informations peuvent nécessiter une vérification supplémentaire avant d'être mises à 
                        jour sur notre plateforme.
                    </p>
                </div>
            ),
        },
        {
            question: "Que faire si mon événement n'est pas approuvé ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Si votre événement n'est pas approuvé, vous recevrez un email de notification expliquant les raisons du refus. 
                        Nous vous encourageons à examiner ces raisons et à apporter les modifications nécessaires pour répondre à nos critères de publication. 
                        Une fois les ajustements effectués, vous pouvez soumettre à nouveau votre événement pour approbation. Si vous avez des questions ou 
                        besoin d'assistance, notre équipe de support est à 
                        votre disposition pour vous aider à résoudre les problèmes et à garantir que votre événement répond à nos standards.
                    </p>
                </div>
            ),
        },
        {
            question: "Quelles sont les modalités de paiement pour les organisateurs sur Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Les modalités de paiement pour les organisateurs sur <b>Events</b> sont conçues pour assurer une gestion transparente 
                        et efficace des fonds. Voici les étapes :
                    </p>
                    <ul>
                        <li>
                            <b>Premier Versement :</b> Après la clôture des ventes de billets, un pourcentage des revenus générés, moins les frais applicables, est versé à l’organisateur dans les quatre jours ouvrables suivant cette clôture.
                        </li>
                        <li>
                            <b>Solde Restant :</b> Le solde restant est versé dans les quatre jours ouvrables suivant la date de l'événement. Aucun frais supplémentaire n'est appliqué au solde restant, garantissant que l'organisateur reçoit la totalité des revenus restants.
                        </li>
                        <li>
                            <b>Week-ends et Jours Fériés :</b> Si la période de quatre jours inclut un week-end ou un jour férié, le paiement est effectué le premier jour ouvrable suivant.
                        </li>
                    </ul>
                    <p>
                        Pour garantir une gestion efficace des fonds et une expérience sans souci, il est essentiel que les organisateurs 
                        fournissent des informations bancaires exactes et à jour comme mentionné dans le contrat des organisateurs. Pour toute question ou clarification concernant 
                        les modalités de paiement, notre équipe de support est disponible pour assister les organisateurs.
                    </p>
                </div>
            ),
        },
        {
            question: "Quels sont les moyens de règlement des paiements pour les organisateurs sur Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        <b>Events</b> propose deux moyens de règlement des paiements pour assurer la flexibilité et la commodité des organisateurs. 
                        Les paiements aux organisateurs sur <b>Events</b> sont effectués par virement bancaire et par chèque.  
                        Les organisateurs doivent s'assurer que leurs coordonnées bancaires sont correctement renseignées pour faciliter les paiements.
                    </p>
                </div>
            ),
        },
        {
            question: "Que se passe-t-il pour les événements qui ont lieu sur plusieurs jours ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Pour les événements s'étalant sur plusieurs jours, chaque journée doit être enregistrée comme un événement distinct. 
                        Cela permet de gérer efficacement les réservations et l'accès pour chaque date. 
                        Assurez-vous de créer un événement séparé pour chaque jour afin de garantir une expérience fluide pour les participants.
                    </p>
                </div>
            ),
        },
        {
            question: "L'organisateur peut-il annuler les réservations des acheteurs ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Non, l'organisateur n'a pas la possibilité d'annuler les réservations des acheteurs. Une fois qu'une réservation est 
                        confirmée, elle reste valide jusqu'à la date de l'événement, conformément à notre politique. 
                        Pour toute question ou situation particulière, les organisateurs doivent contacter notre support.
                    </p>
                </div>
            ),
        },
        {
            question: "Quelles sont les obligations légales des organisateurs sur Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Les organisateurs d'événements sur <b>Events</b> doivent respecter toutes les législations en vigueur, y compris les normes de 
                        sécurité, les droits d'auteur, et les réglementations locales relatives à l'organisation d'événements. 
                        En publiant un événement, l'organisateur s'engage également à fournir des informations exactes et complètes, 
                        et à se conformer aux conditions stipulées dans la <Link className="font-medium text-primary-6000" to="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6">Charte des Organisateurs</Link> et le contrat signé.
                    </p>
                </div>
            ),
        },
        {
            question: "Quel soutien Events offre-t-il aux organisateurs le jour de l'événement ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Le jour de l'événement, <b>Events</b> s'engage à vous aider en trouvant des participants pour votre événement. 
                        Nous mettons également à votre disposition un outil de contrôle et de vérification des tickets, que vous pouvez 
                        utiliser de manière autonome. Cependant, si 
                        vous souhaitez que notre équipe soit présente sur place pour des raisons spécifiques, n'hésitez pas à nous contacter pour en discuter.
                    </p>
                </div>
            ),
        },
        {
            question: "Comment se déroule le contrôle des tickets le jour de l'événement ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Le jour de l'événement, les agents désignés par l'organisateur seront responsables du contrôle des tickets à l'entrée. 
                        Ils utiliseront l'application dédiée pour scanner les tickets des participants. De plus, une option sera disponible pour 
                        télécharger la vidéo explicative ainsi que l'application sur 
                        le site officiel, afin de faciliter le processus de vérification et garantir une entrée fluide pour tous les participants.
                        Tous les validateurs peuvent se connecter simultanément.
                    </p>
                </div>
            ),
        },
        {
            question: "Que se passe-t-il en cas de report ou de déplacement de l'événement ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        En cas de report ou de déplacement de l'événement, l'organisateur doit informer <b>Events</b> le plus rapidement possible. 
                        Les participants seront notifiés des changements, et leur réservation restera valable pour la nouvelle date ou le nouveau lieu.
                    </p>
                </div>
            ),
        },
        {
            question: "Quelles sont les démarches à suivre en cas d'annulation d'un événement par l'organisateur ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        En cas d'annulation d'un événement, l'organisateur doit informer <b>Events</b> au moins deux semaines avant la date prévue. 
                        Cette notification permettra de gérer efficacement les remboursements des participants. 
                        Les organisateurs doivent également prendre en compte que les frais de service ne seront pas remboursés. 
                        Il est important de suivre les conditions stipulées dans la 
                        <Link className="font-medium text-primary-6000" to="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6"> Charte des organisateurs</Link> et le contrat signé 
                        pour garantir une procédure appropriée.
                    </p>
                </div>
            ),
        },
        {
            question: "Quelle est la signification du code de l'événement et son utilité ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Le code de l'événement est un identifiant unique attribué à chaque événement publié sur Events. 
                        Il sert à faciliter la gestion et la vérification des réservations, permettant ainsi aux organisateurs et aux participants 
                        d'accéder rapidement aux informations relatives à l'événement. 
                        Ce code est essentiel pour la validation des tickets à l'entrée et pour toute communication concernant l'événement.
                    </p>
                </div>
            ),
        },
        {
            question: "Puis-je publier un événement sans billet(Entrée libre) ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Non ! Cette politique vise à garantir la qualité des événements proposés et à maintenir une expérience utilisateur 
                        optimale pour les acheteurs. Pour organiser un événement sur <b>Events</b>, 
                        un tarif d'entrée doit être fixé afin de faciliter la gestion des réservations et des participants.
                    </p>
                </div>
            ),
        },
        {
            question: "Quels sont les avantages de promouvoir mon événement sur Events ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Promouvoir votre événement sur <b>Events</b> vous offre de nombreux avantages. Tout d'abord, vous bénéficiez d'une 
                        visibilité accrue auprès d'un large public, ce qui augmente vos chances d'attirer des participants. 
                        La plateforme facilite la gestion de votre événement grâce à des outils intuitifs, de la création à la vente de billets. <br /><br />
                        De plus, notre équipe de support technique est disponible pour vous accompagner à chaque étape, assurant une expérience sans souci. 
                        Vous aurez également accès à des rapports de vente détaillés pour suivre les performances de votre événement en temps réel. <br /><br />
                        Avec <b>Events</b>, vous pouvez profiter de fonctionnalités de promotion intégrées pour attirer davantage d'acheteurs et maximiser vos revenus. 
                        La sécurité des transactions est garantie, protégeant ainsi vos revenus et ceux de vos participants. 
                        Enfin, vous disposerez d'outils de contrôle pour vérifier les billets le jour de l'événement, assurant une entrée fluide pour tous. <br /><br />
                        Promouvoir votre événement sur Events, c'est choisir une solution complète pour réussir votre projet.
                    </p>
                </div>
            ),
        },
        {
            question: "Comment puis-je contacter le support en cas de problème ?",
            answer:
            (
                <div className='prose max-w-full'>
                    <p>
                        Les organisateurs ont plusieurs moyens de contacter le support de <b>Events</b>. Si vous avez déjà publié votre événement, 
                        nous vous recommandons de nous contacter directement par <Link className="font-medium text-primary-6000" to="mailto:support.organizers@app-events.com">Support.organizers@app-events.com</Link> en précisant votre code d'événement. 
                        Cela nous permettra de vous fournir une assistance ciblée et rapide. <br /><br />
                        Si vous n’avez pas encore publié d'événement ou si vous avez des questions générales, 
                        vous pouvez simplement contacter notre support par <Link className="font-medium text-primary-6000" to="mailto:contact@app-events.com">contact@app-events.com</Link> en décrivant votre demande. 
                        Notre équipe est là pour vous aider et répondre à toutes vos préoccupations. <br /><br />
                        N’hésitez pas à <Link to="/nous-contacter" className="font-medium text-primary-6000" >nous solliciter</Link> à tout moment, nous sommes là pour vous accompagner dans la réussite de votre événement !
                    </p>
                </div>
            ),
        },
        
    ];

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const renderFAQs = () => {
        const faqsToRender = activeTab === 'user' ? faqsUser : faqsOrganizer;
        return faqsToRender.slice(0, visibleFAQs[activeTab]).map((faq, index) => (
            activeTab === 'user' ? (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ) : (
                <FAQItemOrganizer key={index} question={faq.question} answer={faq.answer} />
            )
        ));
    };

    const handleShowMore = () => {
        setVisibleFAQs((prevVisibleFAQs) => ({
            ...prevVisibleFAQs,
            [activeTab]: prevVisibleFAQs[activeTab] + 5,
        }));
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 mb-5">
            <Helmet>
                <meta name="description" content="Questions fréquentes sur Events - Trouvez des réponses pour les clients et les organisateurs concernant la réservation de billets, la gestion d'événements, les remboursements et plus encore." />
                <meta 
                    name="keywords" 
                    content="FAQ, questions fréquentes, Events, clients, organisateurs, réservation de billets, gestion d'événements, remboursements, assistance" 
                />
            </Helmet>
            <header className="container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5 mb-10">
                    <h1
                        className="text-center text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                    >
                        Foire Aux Questions (FAQ) - Application Mobile "Events"
                    </h1>

                    <p>
                        Si vous participez ou utilisez notre application de billeterie gratuite et avez des questions, 
                        le plus simple reste de contacter à l'adresse <b>contact@app-events.com </b> 
                        ou accéder à notre page de <Link to="/nous-contacter" style={{color: '#0000d9'}}>contact</Link>.  <br />
                        Sinon, la réponse à votre question se trouve certainement dans les sections ci-dessous.
                    </p>
                    
                </div>
                <div
                    className="flex p-1 space-x-1 bg-primary-900/10 rounded-xl mt-5 max-w-screen-md mx-auto mb-5"
                    role="tablist"
                    aria-orientation="horizontal"
                    style={{maxWidth: "578px"}}
                    >
                    <button
                        className={`w-full py-2.5 text-sm leading-5 font-medium ${activeTab === 'user' ? 'text-primary-700 dark:text-primary-700 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 bg-white dark:bg-neutral-800 shadow' : 'text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60  hover:bg-white/[0.15] dark:hover:bg-neutral-800'}`}
                        id="headlessui-tabs-tab-:r8i:"
                        role="tab"
                        type="button"
                        aria-selected="false"
                        tabIndex={1}
                        data-headlessui-state=""
                        aria-controls="headlessui-tabs-panel-:r8k:"
                        style={{fontSize: "18px"}}
                        onClick={() => handleTabChange('user')}
                    >
                        Aide aux utilisateurs
                    </button>
                    <button
                        className={`w-full py-2.5 text-sm leading-5 font-medium ${activeTab === 'organizer' ? 'text-primary-700 dark:text-primary-700 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 bg-white dark:bg-neutral-800 shadow' : 'text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60  hover:bg-white/[0.15] dark:hover:bg-neutral-800'}`}
                        id="headlessui-tabs-tab-:r8j:"
                        role="tab"
                        type="button"
                        aria-selected="true"
                        tabIndex={0}
                        data-headlessui-state="selected"
                        aria-controls="headlessui-tabs-panel-:r8l:"
                        style={{fontSize: "18px"}}
                        onClick={() => handleTabChange('organizer')}
                    >
                        Aide aux organisateurs
                    </button>
                </div>
            </header>

            <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 container rounded-3xl">
                
                {renderFAQs()}

                <div className="flex mt-4 justify-center items-center">
                    <button
                        disabled={visibleFAQs[activeTab] >= (activeTab === 'user' ? faqsUser.length : faqsOrganizer.length)}
                        onClick={handleShowMore}
                        className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50"
                    >
                        {visibleFAQs[activeTab] >= (activeTab === 'user' ? faqsUser.length : faqsOrganizer.length) ? (
                        `Toutes les questions sont affichées`
                        ) : (
                        <>
                            <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            >
                            <circle
                                className="opacity-25"
                                cx={12}
                                cy={12}
                                r={10}
                                stroke="currentColor"
                                strokeWidth={3}
                            />
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                            </svg>
                            Voir plus
                        </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}
