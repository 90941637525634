import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatNumber } from '../Utils/UtilsNumbersFormat';
import { formatPrice } from '../Utils/UtilsPricesFormat';
import axios from 'axios';

export default function SalesSecondContentTab({ eventData, responseDataSales }) {
    const [showVideo, setShowVideo] = useState(false);
    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    const handleImageClick = () => {
      setShowVideo(true);
    };

    const handleDownloadClick = async () => {
        try {
            // Envoyer une requête POST à votre API Laravel pour enregistrer l'activité
            await axios.post('https://backoffice.app-events.com/api/request/log-activity/store-activityDownload-EventsAppScan:m/c/7fea0-6e99-4a-40dbc09572', {
                activity: 'Téléchargement de l\'application de Events scan',
                userAgent: eventData.isID
            });
        
            // Lancer le téléchargement du fichier APK
            const link = document.createElement('a');
            link.href = `${imagePath}APK-Base2482-Events_scan32/Events-Scan.apk`;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            // console.error('Erreur lors de l\'enregistrement de l\'activité:', error);
        }
    };

    return (
        <>
            {eventData.status === 1 && responseDataSales != null ? (
                <div>
                    <div className="mt-4 border-neutral-200 rounded-2xl border max-w-6xl mx-auto p-2 px-2 sm:p-6 xl:p-8">
                        <div>

                            <div className="sm:pr-20 relative flex items-center">
                                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
                                <h3 className="text-2xl font-semibold">Détails des ventes</h3>
                            </div>
                            <div className="nc-Card3 relative flex flex-col-reverse sm:flex-row justify-center sm:items-center rounded-[40px] group p-2 px-2 sm:p-4 xl:p-6">
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 sm:gap-6 md:gap-8">
                                    <div className="md:w-72 text-blue-800 bg-blue-100 p-6 rounded-xl dark:border-neutral-800">
                                        <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                        {formatNumber(eventData.total_billets)}
                                        </h3>
                                        <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                            Total de Billets
                                        </span>
                                    </div>
                                    <div className="md:w-72 text-blue-800 bg-blue-100 p-6 rounded-xl dark:border-neutral-800">
                                        <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                        {formatNumber(eventData.billets_vendus)}
                                        </h3>
                                        <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                            Billets Vendus
                                        </span>
                                    </div>
                                    <div className="md:w-72 text-blue-800 bg-blue-100 p-6 rounded-xl dark:border-neutral-800">
                                        <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                        {formatNumber(eventData.billets_restants)}
                                        </h3>
                                        <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                            Billets Restants
                                        </span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="flex p-1 space-x-1 rounded-xl mt-5 mx-auto mb-5 sm:flex-row xl:flex-row flex-col justify-center sm:items-center">
                                {responseDataSales.map((sale, index) => (
                                    <span key={index} className="inline-flex px-2.5 py-1 rounded-lg font-semibold text-sm text-gray-800 border-neutral-200 border mb-4">
                                        {sale.pass_id}
                                        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-bold" style={{fontSize: '1.25rem'}}> · </span> 
                                        {formatPrice(sale.prix_unitaire)} 
                                        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-bold" style={{fontSize: '1.25rem'}}> · </span> 
                                        {formatNumber(sale.total_billet)} tickets
                                    </span>
                                ))}
                            </div>
                            <div className="relative flex flex-col sm:flex-row mb-8 lg:mb-16 text-neutral-900 dark:text-neutral-50">
                                <div className="text-center w-full max-w-2xl mx-auto">

                                    <h2 className="text-3xl md:text-4xl font-semibold">{formatPrice(eventData.sold_events)}
                                    </h2>
                                    <div className="inline-flex px-2.5 py-1 rounded-full font-medium text-xs bg-red-700 text-red-50">
                                        Montant Net
                                    </div>
                                    
                                    <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
                                        Solde Evenementiel
                                    </span>
                                </div>
                            </div>
                            <div className="sm:pr-20 relative flex items-center">
                                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
                                <h3 className="text-2xl font-semibold">Autres</h3>
                            </div>
                            <span className="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300">
                                <b>La date de clôture des ventes de votre événement sur Events est fixée au {eventData.date_cloture}. </b><br />
                                Le solde affiché correspond au montant net des ventes, avant application des modalités éventuelles. Lors du retrait des fonds, ce montant sera recalculé selon les modalités définies dans la Charte des Organisateurs et incluses dans le contrat signé <br /><br />

                                Pour plus de détails sur notre fonctionnement, veuillez consulter notre <Link to={"/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6"} className='text-blue-800 font-semibold'>Charte des Organisateurs Events</Link> . En cas de besoin d'assistance, notre équipe est à votre disposition. <Link to={"/Organisateurs/AssistanceEvents/contact-assisance-organisateurs"} className='text-blue-800 font-semibold'>Consultez dès maintenant</Link>
                            </span>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 max-w-6xl mx-auto p-2 px-0 sm:p-6 xl:p-8' style={{paddingLeft: 'inherit', paddingRight: 'inherit'}}>
                        <div className="listingSection__wrap mt-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden">
                            <h2 className="text-2xl font-semibold pl-2 pt-2">Regardez la Vidéo Explicative</h2>
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
                            <div className="text-neutral-6000 dark:text-neutral-300 p-3 sm:pr-6">
                                <p className='pb-6'>
                                    Pour vous familiariser avec l'utilisation de l'application de scan, nous avons préparé une 
                                    vidéo explicative détaillée. Cette vidéo vous guidera pas à pas dans le processus de téléchargement, 
                                    d'installation et 
                                    d'utilisation de l'application, vous permettant ainsi d'être pleinement préparé le jour de l'événement.
                                </p>
                                <div className="group relative aspect-h-16 aspect-w-16 rounded-2xl cursor-pointer overflow-hidden sm:aspect-h-12 sm:rounded-3xl lg:aspect-h-9 will-change-transform"
                                    title="Vidéo Explicative pour l'Application de Events Scan" onClick={handleImageClick}>
                                    {showVideo ? (
                                        <div className="absolute inset-0 w-full h-full">
                                        <video
                                            className="w-full h-full"
                                            controls
                                            title="Vidéo Explicative pour l'Application de Events Scan"
                                        >
                                            <source src={`${imagePath}video-Base214586f-Events68d6/Ad.mp4`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                      </div>
                                    ) : (
                                        <>
                                            <div className="absolute inset-0 flex items-center justify-center z-10">
                                                <div className="nc-NcPlayIcon2 bg-white relative rounded-full shadow-inner w-8 h-8 md:w-10 md:h-10">
                                                    <span className="absolute inset-0 flex items-center justify-center text-primary-500">
                                                        <svg
                                                        className="w-5 h-5"
                                                        width={24}
                                                        height={24}
                                                        fill="currentColor"
                                                        viewBox="0 0 24 24"
                                                        >
                                                        <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={1}
                                                            d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
                                                        />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="nc-NcImage absolute inset-0 w-full h-full">
                                                <img
                                                    src={`${imagePath}/images/events-font-image.jpg`}
                                                    className="object-cover w-full h-full transform transition-transform group-hover:scale-110 duration-300 nc-will-change-transform"
                                                    alt="Vidéo Explicative pour l'Application de Events Scan"
                                                    title="Vidéo Explicative pour l'Application de Events Scan"
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="listingSection__wrap mt-4 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden" style={{height: 'fit-content'}}>
                            <h2 className="text-2xl font-semibold pl-2 pt-2">Téléchargez l'application de scan</h2>
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
                            <div className="text-neutral-6000 dark:text-neutral-300 p-3 sm:pr-6">
                                <p className='pb-6'>
                                    Notre application de scan est un outil essentiel pour vérifier rapidement et efficacement les 
                                    billets de vos participants. Elle est disponible uniquement sous Android pour téléchargement sur notre site officiel. 
                                    Assurez-vous que tous vos agents de 
                                    contrôle disposent de cette application pour garantir une entrée fluide et sécurisée à votre événement.
                                </p>
                                <button
                                    className="w-full mt-4 md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl text-blue-800 bg-blue-100 z-10"
                                    type="button"
                                    onClick={handleDownloadClick}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: "11px"
                                    }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"
                                        />
                                    </svg>
                                    <span className="ml-2 text-blue-800 text-sm font-medium">
                                        Telecharger la version récente
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-4 border-neutral-200 rounded-2xl border max-w-6xl mx-auto p-2 px-2 sm:p-6 xl:p-8">
                    <div className="flex py-4 px-2 space-x-1 mt-5 mx-auto mb-5 justify-center sm:items-center rounded-lg bg-red-100" style={{color: 'rgb(122 75 75)'}}>
                        <span>
                            <svg
                            viewBox="0 0 24 24"
                            width={20}
                            height={20}
                            stroke="currentColor"
                            strokeWidth={2}
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="me-2"
                            >
                            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                            <line x1={15} y1={9} x2={9} y2={15} />
                            <line x1={9} y1={9} x2={15} y2={15} />
                            </svg>
                        </span>
                        <span className="ml-3 mr-3 font-semibold">
                            Événement non enregistré ou en attente d'approbation. Veuillez patienter.
                        </span>
                    </div>
                </div>
            )}
        </>
    )
}
