import { useEffect } from 'react';
import axios from 'axios';
import UAParser from 'ua-parser-js';

const useTrackVisitor = () => {
  useEffect(() => {
    const isNewVisitor = !localStorage.getItem('isNewVisitor');

    if (isNewVisitor) {
      axios.get('https://api.ipify.org?format=json')
        .then(response => {
          const ipAddress = response.data.ip;
          const userAgent = navigator.userAgent;
          const parser = new UAParser();
          const result = parser.setUA(userAgent).getResult();
          const deviceType = result.device.type || 'desktop';
          const osName = result.os.name;
          const osVersion = result.os.version;

          // Utilisation de l'API ipapi pour obtenir des informations de localisation détaillées
          axios.get(`https://ipapi.co/${ipAddress}/json/`)
            .then(response => {
              const { country_name, region, city } = response.data;

              axios.post('https://backoffice.app-events.com/api/request/eventsVisitorWebSite/store:m/c/2c37fea0-6e99-4ae1-93e1-0240dbc09572', {
                ip_address: ipAddress,
                user_agent: userAgent,
                device_type: deviceType,
                os_name: osName,
                os_version: osVersion,
                country: country_name,
                region: region,
                city: city
              })
              .then(() => {
                localStorage.setItem('isNewVisitor', 'false');
              })
              .catch(error => {
                // console.error('Erreur lors de l\'enregistrement de la visite:', error);
              });
            })
            .catch(error => {
              // console.error('Erreur lors de la récupération des informations de localisation:', error);
            });
        })
        .catch(error => {
          // console.error('Erreur lors de la récupération de l\'adresse IP:', error);
        });
    }
  }, []);
};

export default useTrackVisitor;
