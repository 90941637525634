import React from 'react'
import { format, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function RecapFirstContentTab({ responseDataActivities }) {

    // Fonction pour formater la date en fonction de la différence en secondes, minutes, heures et jours
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffSeconds = differenceInSeconds(now, date);
        const diffMinutes = differenceInMinutes(now, date);
        const diffHours = differenceInHours(now, date);
        const diffDays = differenceInDays(now, date);

        if (diffSeconds < 60) {
            return `Il y a environ ${diffSeconds} seconde${diffSeconds > 1 ? 's' : ''}`;
        } else if (diffMinutes < 60) {
            return `Il y a environ ${diffMinutes} minute${diffMinutes > 1 ? 's' : ''}`;
        } else if (diffHours < 24) {
            return `Il y a environ ${diffHours} heure${diffHours > 1 ? 's' : ''}`;
        } else if (diffDays <= 3) {
            return `Il y a environ ${diffDays} jour${diffDays > 1 ? 's' : ''}`;
        } else {
            return format(date, 'dd/MM/yyyy à HH:mm', { locale: fr });
        }
    };

    // Fonction pour afficher une activité en fonction de son statut
    const renderActivity = (activity) => {
        const { status, created_at, validations_task } = activity;
        const formattedDate = formatDate(created_at);
        const taskName = validations_task.name;
        const taskDescription = validations_task.description;

        let iconClassName, bgColor, textColor, message;

        switch (status) {
            case 'success':
                iconClassName = 'las la-check';
                bgColor = 'bg-green-100';
                textColor = 'rgb(51 78 62 / 98%)';
                message = `Bravo ! "${taskName}" éffctué(e) avec succès.`;
                break;
            case 'failure':
                iconClassName = 'las la-info';
                bgColor = 'bg-red-100';
                textColor = 'rgb(122 75 75)';
                message = `Désolé ! "${taskName}" échoué(e).`;
                break;
            default:
                bgColor = 'dark:bg-neutral-800';
                textColor = 'text-neutral-700';
                message = `Votre "${taskName}" est à l'étape de validation. Merci de bien vouloir patienter.`;
                break;
        }

        return (
            <div key={activity.id} className="flex my-5 md:my-0 w-full">
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                    <span className={`block w-6 h-6 rounded-full border border-neutral-400 ${iconClassName} text-2xl`} />
                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                </div>
                <div className="ml-4 space-y-10 text-sm w-full">
                    <div className={`flex flex-col space-y-1 rounded-lg ${bgColor} px-4 py-3`} style={{ color: textColor }}>
                        <span className="text-neutral-500 dark:text-neutral-400">
                            {formattedDate}
                        </span>
                        <span className="font-semibold">
                            {message}
                        </span>
                        <p>{taskDescription}</p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="max-w-4xl mx-auto nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden space-y-6">
            <div className="sm:pr-20 relative">
                <h3 className="text-2xl font-semibold">Contrôle des Procédures</h3>
            </div>

            <div className="flex flex-col space-y-1 rounded-lg bg-green-100 dark:bg-neutral-800 px-4 py-3" style={{color: 'rgb(51 78 62 / 98%)'}}>
                
                <span className="text-sm">
                Votre demande est en cours de traitement. Nous vérifions chaque détail avec soin pour assurer la qualité et la conformité. Nous vous tiendrons informé dès que chaque étape sera validée.
                </span>
            </div>
                    
            {/* <div > */}
                {/* <div className="flex-shrink-0 flex flex-col items-center py-2">
                    <span className="block w-6 h-6 rounded-full border border-neutral-400 las la-info text-2xl" />
                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                    <span className="block w-6 h-6 rounded-full border border-neutral-400" />
                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                    <span className="block w-6 h-6 rounded-full border border-neutral-400 las la-check text-2xl" />
                </div> */}
                
                    {/* Affichez chaque activité */}
                    {responseDataActivities.map(activity => renderActivity(activity))}
                    
                {/* </div> */}
            {/* </div> */}
        </div>
    )
}
