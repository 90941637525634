import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import validator from "validator";

export default function ContactPage() {
    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Contactez-nous - Events";
        
    }, []);

    //   Contact form
    const [errors, setErrors] = useState({});
    const [formValue, setFormValue] = useState({nom: '', objet: '', email: '', message: ''});
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const handleValidation = (e) => {
        const {name, value} = e.target;
        setFormValue({...formValue, [name]: value});
    }

    // Créez une fonction pour réinitialiser les états du formulaire
    const resetForm = () => {
        setFormValue({ nom: '', objet: '', email: '', message: '' }); // Réinitialise les valeurs des champs à vide
        setErrors({}); // Efface les éventuelles erreurs de validation
        setIsSubmit(false); // Réinitialise l'état de soumission à false
    };

    const validateForm = (value) => {
        const newErrors = {};

        // Validation du champ nom
        const trimmedNom = value.nom.trim();
        if (!trimmedNom) {
            newErrors.nom = "Ce champ est requis";
        } else if (!validator.isLength(trimmedNom, { min: 3 })) {
            newErrors.nom = "Le nom complet doit contenir au moins 3 caractères";
        } else if (!/^[a-zA-Z\sÀ-ÿ'-]+$/.test(trimmedNom)) {
            newErrors.nom = "Le nom complet ne doit contenir que des lettres";
        }

        // Validation du champ objet
        const trimmedObjet = value.objet.trim();
        if (!trimmedObjet) {
            newErrors.objet = "Ce champ est requis";
        } else if (!validator.isLength(trimmedObjet, { min: 10 })) {
            newErrors.objet = "Veuillez rédiger correctement votre objet de contact";
        }

        // Validation du champ email
        const trimmedEmail = value.email.trim();
        if (!trimmedEmail) {
            newErrors.email = "L'e-mail est requis";
        } else if (!validator.isEmail(trimmedEmail)) {
            newErrors.email = "L'adresse e-mail n'est pas valide";
        }

        // Validation du champ message
        const trimmedMessage = value.message.trim();
        if (!trimmedMessage) {
            newErrors.message = "Ce champ est requis";
        } else if (!validator.isLength(trimmedMessage, { min: 15 })) {
            newErrors.message = "Veuillez rédiger correctement votre préoccupation";
        }

        return newErrors;
    };

    useEffect(() => {
        // Si le formulaire est soumis et qu'il n'y a pas d'erreurs, effectuez une action
        if (Object.keys(errors).length === 0 && isSubmit) {
            // console.log(formValue);
        }
    }, [errors, formValue, isSubmit]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Valider le formulaire avant de passer à l'étape suivante
        const formErrors = validateForm(formValue);

        // Vérifier si le formulaire est valide
        if (Object.keys(formErrors).length === 0) {
            setLoading(true); // Activer le chargement
            
            try {
                axios.post("https://backoffice.app-events.com/api/request/eventsApp/sendContactToUsersInterface/get-datas-to-user33-5452e-e873", formValue,{
                    headers: {
                    withCredentials: true,
                    "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    // Mise à jour du statut selon la réponse du serveur
                    setSuccessMessage(response.data.messages);
        
                    setLoading(false);
                    resetForm(); // Réinitialise le formulaire
                    // Fermer le message
                    setTimeout(() => setSuccessMessage(""), 3000);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 422) {
                            // Mise à jour du statut en cas d'erreur de validation
                            setValidationError(
                                "Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !"
                            );
                            setLoading(false);
                            // Fermer le message
                            setTimeout(() => setValidationError(""), 3000);
                        }else if (error.response.status === 500) {
                            setValidationError(error.response.data.errors);
                            setLoading(false);

                            // Fermer le message
                            setTimeout(() => setValidationError(""), 3000);
                        } else {
                            setValidationError(
                                "OUPS ! Une erreur est survenu, Veuillez réessayer ulterieurement."
                            );
                            setLoading(false);
                            setTimeout(() => setValidationError(""), 3000);
                        }
                    } else{

                        setValidationError(
                            "OUPS ! Une erreur est survenu, Veuillez réessayer ulterieurement."
                        );
                        setLoading(false);
                        setTimeout(() => setValidationError(""), 3000);
                    }
                });
            } catch (err) {
                setValidationError("Erreur de traitement ! Veuillez réessayer s'il vous plait !");
                setLoading(false);
                // Fermer le message
                setTimeout(() => setValidationError(""), 3000);
            }
        }

        // Mettre à jour les erreurs après la validation
        setErrors(formErrors);
        
        // Définir isSubmit à true pour activer useEffect
        setIsSubmit(true);
    };

    return (
        <>
            <div className="glow-container">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>

            </div>
            <div className='z-5 relative'>
                <Helmet>
                    <meta name="description" content="Contactez Events pour toute question, assistance ou information sur la gestion et la promotion de vos événements. Notre équipe est prête à vous aider." />
                    <meta 
                        name="keywords" 
                        content="Contact, Events, support, assistance, gestion d'événements, promotion d'événements, aide, informations, service client" 
                    />
                </Helmet>
                
                <div className="mb-10 lg:mb-15 z-5 relative">
                    <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Contact
                    </h2>
                    <div className="container max-w-7xl mx-auto">
                        <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
                        <div className="max-w-xl space-y-8">
                            <div className=''>
                            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                🗺 ADDRESSE
                            </h3>
                            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                Abidjan, Côte d'Ivoire
                            </span>
                            </div>
                            <div>
                            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                💌 E-MAILS
                            </h3>
                            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                <Link to={"mailto:contact@app-events.com"}>Contact@app-events.com</Link>
                            </span>
                            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                <Link to={"mailto:support.organizers@app-events.com"}>Support.organizers@app-events.com</Link>
                                <small className='font-semibold'> (Organisateurs Events)</small>
                            </span>
                            </div>
                            <div>
                            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                ☎ TELEPHONE
                            </h3>
                            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                +225 07 17 51 9518
                            </span>
                            </div>
                            <div>
                            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                🌏 RESEAU SOCIAUX
                            </h3>
                            <nav
                                className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 mt-2"
                                data-nc-id="SocialsList"
                            >
                                <Link
                                className="block"
                                to="https://www.facebook.com/profile.php?id=61558762937775"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Facebook"
                                >
                                <i className="lab la-facebook-square" />
                                </Link>
                                {/* <Link
                                className="block"
                                to="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Twitter"
                                >
                                <i className="lab la-twitter" />
                                </Link>
                                <Link
                                className="block"
                                to="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Youtube"
                                >
                                <i className="lab la-youtube" />
                                </Link> */}
                                <Link
                                className="block"
                                to="https://www.instagram.com/events.app225?igsh=YzljYTk1ODg3Zg=="
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Instagram"
                                >
                                <i className="lab la-instagram" />
                                </Link>
                                <Link
                                className="block"
                                to="https://wa.me/"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Whatsapp Support Events"
                                >
                                <i className="lab la-whatsapp" />
                                </Link>
                            </nav>
                            </div>
                        </div>
                        <div>
                            <h2 className="uppercase font-bold dark:text-neutral-200 tracking-wider" style={{marginBottom: '2rem'}}>
                                OU ENVOYEZ-NOUS UN MESSAGE
                            <hr/>
                            </h2>
                            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                                <label className="block">
                                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 ">
                                    Votre nom complet
                                    <span className="text-red-600"> *</span>
                                    </label>
                                    <input 
                                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                                    placeholder="Exemple: Elysee ALLA"
                                    type="text"
                                    onChange={handleValidation}
                                    required
                                    name="nom"
                                    value={formValue.nom}
                                    />
                                    {errors.nom && (
                                    <small className="text-red-600 mt-2">
                                        {errors.nom}
                                    </small>
                                    )}
                                </label>
                                <label className="block">
                                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 ">
                                    Objet du mail
                                    <span className="text-red-600"> *</span>
                                    </label>
                                    <input 
                                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                                    placeholder="Entrez un objet de contact"
                                    type="text"
                                    onChange={handleValidation}
                                    required
                                    name="objet"
                                    value={formValue.objet}
                                    />
                                    {errors.objet && (
                                    <small className="text-red-600 mt-2">
                                        {errors.objet}
                                    </small>
                                    )}
                                </label>
                                <label className="block">
                                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 ">
                                    Adresse E-mail
                                    <span className="text-red-600"> *</span>
                                    </label>
                                    <input
                                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                                    placeholder="example@example.com"
                                    type="email"
                                    onChange={handleValidation}
                                    required
                                    name="email"
                                    value={formValue.email}
                                    />
                                    {errors.email && (
                                        <small className="text-red-600 mt-2">
                                            {errors.email}
                                        </small>
                                    )}
                                </label>
                                <label className="block">
                                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 ">
                                        Message
                                        <span className="text-red-600"> *</span>
                                    </label>
                                    <textarea
                                    className="block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1"
                                    rows={6}
                                    placeholder='Entrez le contenu de votre message'
                                    onChange={handleValidation}
                                    required
                                    name="message"
                                    value={formValue.message}
                                    />
                                    {errors.message && (
                                    <small className="text-red-600 mt-2">
                                        {errors.message}
                                    </small>
                                    )}
                                </label>
                                <div>
                                <button
                                    className={`nc-Button relative h-auto inline-flex items-center justify-center rounded-xl transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${loading ? 'pointer-events-none opacity-50' : ''}`}
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    {loading ? (
                                        <>
                                            <svg
                                                className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx={12}
                                                    cy={12}
                                                    r={10}
                                                    stroke="currentColor"
                                                    strokeWidth={3}
                                                />
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                />
                                            </svg>
                                            Envoi en cours...
                                        </>
                                    ) : (
                                        <>
                                            Envoyer 
                                            <i className="las la-paper-plane ml-2"></i>
                                        </>
                                    )}
                                </button>
                                </div>
                            </form>

                            
                            {successMessage && (
                                <div
                                    className="px-2.5 py-1 mt-3 flex nc-Badge rounded-full font-medium text-xs text-green-800 bg-green-100"
                                    style={{width: "fit-content"}}
                                >
                                    <span>
                                        <svg
                                        viewBox="0 0 24 24"
                                        width={20}
                                        height={20}
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="me-2"
                                        >
                                        <polyline points="9 11 12 14 22 4" />
                                        <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
                                        </svg>
                                    </span>
                                    <span className="text-sm ml-3 mr-3">{successMessage}</span>
                                </div>
                            )}
                            {validationError && (
                                <div
                                    className="px-2.5 py-1 p-3 mt-3 flex nc-Badge rounded-full font-medium text-xs text-red-800 bg-red-100"
                                    style={{width: "fit-content"}}
                                >
                                    <span>
                                        <svg
                                        viewBox="0 0 24 24"
                                        width={20}
                                        height={20}
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="me-2"
                                        >
                                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                                        <line x1={15} y1={9} x2={9} y2={15} />
                                        <line x1={9} y1={9} x2={15} y2={15} />
                                        </svg>
                                    </span>
                                    <span className="text-sm ml-3 mr-3">{validationError}</span>
                                </div>
                            )}
                        </div>

                        {/* <div className="flex my-5 md:my-0 w-full">
                                <div className="flex-shrink-0 flex flex-col items-center py-2">
                                    <span className="block w-6 h-6 rounded-full border border-neutral-400 las la-info text-2xl" />
                                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                                    <span className="block w-6 h-6 rounded-full border border-neutral-400" />
                                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                                    <span className="block w-6 h-6 rounded-full border border-neutral-400 las la-check text-2xl" />
                                </div>
                                <div className="ml-4 space-y-10 text-sm w-full">
                                    <div className="flex flex-col space-y-1 rounded-lg bg-red-100 dark:bg-neutral-800 px-4 py-3" style={{color: 'rgb(122 75 75)'}}>
                                        <span className=" text-neutral-500 dark:text-neutral-400">
                                            Monday, August 12 · 10:00
                                        </span>
                                        <span className="font-semibold">
                                            Desole ! Votre evenement ne respect pas les normes de publication.
                                        </span>
                                    </div>
                                    <div className="flex flex-col space-y-1 rounded-lg dark:bg-neutral-800 px-4 py-3">
                                        <span className=" text-neutral-500 dark:text-neutral-400">
                                            Monday, August 16 · 10:00
                                        </span>
                                        <span className="font-semibold">
                                            Votre demande est a l'etape de validation. L'equipe de validation vous contactera bientot.
                                        </span>
                                    </div>
                                    <div className="flex flex-col space-y-1 rounded-lg bg-green-100 dark:bg-neutral-800 px-4 py-3" style={{color: 'rgb(51 78 62 / 98%)'}}>
                                        <span className=" text-neutral-500 dark:text-neutral-400">
                                            Monday, August 12 · 10:00
                                        </span>
                                        <span className="font-semibold">
                                            Bravo ! Votre evenement a ete publié avec succes.
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> */}

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
