import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'

export default function MainPage() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Devenir Organisateur événementiel - Events";
        
    }, []);

    return (
        <div className='bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'>
            <Helmet>
                <meta name="description" content="Bienvenue sur la page d'accueil dédiée aux organisateurs de Events. Publiez, gérez et promouvez vos événements facilement avec notre plateforme intuitive. Rejoignez-nous pour atteindre un large public et maximiser vos ventes de billets." />
                <meta 
                    name="keywords" 
                    content="organisateurs d'événements, publication d'événements, gestion d'événements, promotion d'événements, vente de billets, Events" 
                />
            </Helmet>
            <div
                className="nc-BgGlassmorphism absolute inset-x-0 md:top-10 xl:top-40 min-h-0 pl-20 py-24 flex overflow-hidden z-0"
                data-nc-id="BgGlassmorphism"
            >
                <span className="block bg-[#ef233c] w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96" />
                <span className="block bg-[#04868b] w-72 h-72 -ml-20 mt-40 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96 nc-animation-delay-2000" />
            </div>
            <div className="container px-1 sm:px-4 mb-4" style={{height: '30rem'}}>
                <div className="nc-SectionHero3 relative ">
                    <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
                        <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
                            Publiez Votre Événement en Toute Simplicité
                        </span>
                        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-5xl !leading-[115%] ">
                            Devenez Organisateur <br /> sur Events
                        </h2>
                        <Link to={"/demande"} className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base lg:text-lg font-medium px-6 py-3 lg:px-8 lg:py-2 rounded-xl ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 focus:outline-none focus:ring-offset-2 dark:focus:ring-offset-0">
                            Commencer Maintenant
                        </Link>
                        <span className="absolute block w-6 h-9 rounded-full border border-neutral-400" style={{top: '18rem'}} />
                    </div>

                    <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
                        {/* <img
                            className="absolute inset-0 object-cover rounded-xl"
                            src="./static/media/travelhero2.7521afd7ebffff7aa945.png"
                            alt="hero"
                        /> */}
                    </div>
                </div>
                
            </div>

            <div className="">
                <div className="nc-SectionHowItWork" style={{marginBottom: '5rem'}}>
                    <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-center justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50" style={{textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <div className="text-center w-full max-w-2xl mx-auto">
                            <h2 className="text-3xl md:text-4xl font-semibold">Pourquoi Choisir Events pour Publier Votre Événement ?</h2>
                        </div>
                        <span className="mt-2 px-2 md:mt-3 font-normal block text-base sm:text-lg text-neutral-500 dark:text-neutral-400 w79">
                            
                            Choisir Events, c'est opter pour une plateforme fiable qui facilite la gestion de vos événements, rejoignez-nous et transformez chaque événement en une expérience mémorable.
                        </span>
                    </div>
                </div>

                <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
                    <div className="block flex-grow mb-24 lg:mb-0">
                        <div className="lg:sticky lg:top-24">
                            <div className="w-full items-center space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
                                <img
                                    className="inset-0 w-full h-full object-cover"
                                    src="../../assets/images/Events.avif"
                                    alt="Image illustration"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                        <div className="prose">
                            <ol>
                                <li>
                                    <h2 className="text-xl font-semibold">Flexibilité de Publication</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        Découvrez la liberté de gérer vos événements comme bon vous semble avec notre application de réservation de tickets. Que ce soit pour des concerts, des conférences ou des spectacles (etc ...) notre plateforme vous permet de publier et de personnaliser vos événements facilement et rapidement. <br /><br /> Profitez d'une interface intuitive et d'outils puissants pour atteindre votre audience et maximiser vos ventes en toute simplicité. 
                                        Faites de chaque événement un succès avec notre solution flexible et adaptée à tous vos besoins. 
                                        <Link to={"/demande"} className="font-medium text-primary-6000">
                                            &nbsp;&nbsp;Commencer Maintenant
                                        </Link>
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Publication Facile et Rapide</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        Simplifiez la mise en ligne de vos événements avec notre interface intuitive. En quelques clics, votre événement est publié et prêt à recevoir des réservations.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Contrôlez vos ventes : Choisissez votre date de clôture et recevez vos paiements rapidement !</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        En tant qu'organisateur d'événements, nous comprenons l'importance de la flexibilité et de la maîtrise 
                                        de vos ventes. C'est pourquoi, avec notre plateforme Events, 
                                        nous vous offrons la possibilité de choisir vous-même la date de clôture de vos ventes de billets.
                                    </span>
                                    <span className="listingSection__wrap li-style block mt-2 mb-5 text-neutral-500 dark:text-neutral-400">
                                        Cela signifie que vous pouvez décider quand vous souhaitez arrêter les ventes et 
                                        <Link to={"/demande"} className="font-medium text-green-800"> commencer à recevoir vos paiements</Link>. 
                                        Que vous souhaitiez clôturer les ventes bien avant l'événement pour mieux planifier ou continuer les ventes jusqu'à la dernière minute pour maximiser vos revenus, la décision vous appartient entièrement.
                                    </span>
                                    <span className="listingSection__wrap li-style block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        Avec cette fonctionnalité, vous avez le contrôle total sur votre trésorerie et pouvez planifier en toute confiance. 
                                        Recevez vos paiements rapidement après la date de clôture choisie et gérez vos finances avec plus de précision et de sérénité. <br /><br />
                                        <Link to={"/demande"} className="font-medium text-green-800">Rejoignez la communauté des organisateurs d'événements</Link> qui profitent déjà de cette flexibilité sur Events et optimisez vos ventes dès aujourd'hui!
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Notre Application</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        Nous avons développé pour vous une solution fiable et robuste dans une application mobile. Changez de perspective avec notre application de reservation pour tous types d'evenement.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Gestion Intégrée des Billets</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        Suivez les ventes de billets en temps réel et gérez les entrées facilement. Vous avez accès à toutes les informations nécessaires pour assurer le bon déroulement de votre événement.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Paiements Sécurisés et Fiables</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                    Bénéficiez d'un système de paiement sécurisé pour vous et vos participants. Nos technologies avancées garantissent la sécurité de chaque transaction, vous offrant ainsi une tranquillité d'esprit totale. Recevez vos fonds rapidement grâce à des versements automatisés et transparents, simplifiant la gestion financière de votre événement.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Validation Rapide des Tickets</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        Nous utilisons notre système de scan pour vérifier les tickets (QR Code) des participants. La validation se fait en quelques secondes, offrant une efficacité bien supérieure à celle des tickets papiers. Cela réduit non seulement les files d'attente mais améliore également l'expérience globale des participants.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Support Dédié 24h/24 - 7j/7</h2>
                                    <span className="block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                        Profitez d'une assistance personnalisée pour répondre à toutes vos questions et vous accompagner dans l'organisation de votre événement. Notre équipe est à votre disposition pour vous aider à chaque moment. Que ce soit pour des conseils sur la mise en ligne de votre événement ou pour une assistance technique, nous sommes là pour vous.
                                    </span>
                                </li>
                            </ol>
                            <span className="listingSection__wrap block mt-2 mb-10 text-neutral-500 dark:text-neutral-400">
                                En choisissant Events, vous optez pour bien plus qu'une simple plateforme de gestion d'événements. Nous sommes là pour vous accompagner et vous offrir les outils dont vous avez besoin pour organiser des événements exceptionnels, créer des expériences mémorables et atteindre vos objectifs avec succès.
                                <br />
                                <Link to={"/Events-usedFolder/FAQs-events?tab=organizer"} className="font-medium text-primary-6000">
                                    Centre d'aide organisateurs
                                </Link>
                            </span>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
