import { Routes, Route } from "react-router-dom";
import Hero from "./pages/Hero";
import Layout from "./components/Layout";
import Error404 from "./pages/Error404";
import WizardForm from './pages/WizardForm'
import ContactPage from "./pages/ContactPage";
import AboutUs from "./pages/AboutUs";
import CGU from "./pages/CGU";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FAQs from "./pages/FAQs";
import Step3Form from "./components/Step3Form";
import CongratulateContent from "./pages/CongratulateContent";
import Oganizer_PolicyEvents from "./pages/Oganizer_PolicyEvents";
import MainPage from "./pages/OrganizerFolder/MainPage";
import ConsultationsHomePage from "./pages/OrganizerFolder/ConsultationsHomePage";
import OganizersAssistancePage from "./pages/OrganizerFolder/OganizersAssistancePage";
import useTrackVisitor from "./hooks/useTrackVisitor";
import InscriptionNewsLettersEvents from "./pages/InscriptionNewsLettersEvents";

function App() {
    
    useTrackVisitor();

    return (
        <Routes>
            <Route element={<Layout />}>
            <Route index element={<Hero />} />
            <Route path="*" element={<Error404 />} />
            <Route path="/demande/3" element={<Step3Form />} />
            <Route path="/nous-contacter" element={<ContactPage />} />
            <Route path="/a-propos-de-l'appli-events" element={<AboutUs />} />
            <Route path="/Events-usedFolder/conditions-generales-utilisations" element={<CGU />} />
            <Route path="/Events-usedFolder/politique-confidentielle" element={<PrivacyPolicy />} />
            <Route path="/Events-usedFolder/FAQs-events" element={<FAQs />} />
            <Route path="/Congratulate/sender/:token/71190cff-131a-69fce9a4f1b6" element={<CongratulateContent />} />
            <Route path="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6" element={<Oganizer_PolicyEvents />} />
            {/* // ROUTED DES PAGES DE FORMULAIRES DE DEMANDE */}
            <Route path="/demande" element={<WizardForm />} />
            {/* // ROUTED DES PAGES DES ORGANISATEURS */}
            <Route path="/organisateurs/devenir-organisateur-events" element={<MainPage />} />
            <Route path="/Organisateurs/Consultations/demandes-publication-events" element={<ConsultationsHomePage />} />
            <Route path="/Organisateurs/AssistanceEvents/contact-assisance-organisateurs" element={<OganizersAssistancePage />} />
            {/* ROUTE DE NEWSLETTER */}
            <Route path="/events-App/newsletter-inscription/r/" element={<InscriptionNewsLettersEvents />} />
            </Route>
        </Routes>
    );
}

export default App;
