import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'

export default function PrivacyPolicy() {
    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Politique de confidentialité - Events";
        
      }, []);
    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 mb-5">
            <Helmet>
                <meta name="description" content="Consultez les politiques de confidentialité de Events pour comprendre comment nous collectons, utilisons et protégeons vos données personnelles. Votre confidentialité est notre priorité." />
                <meta 
                    name="keywords" 
                    content="politique de confidentialité, protection des données, collecte de données, utilisation des données, confidentialité des utilisateurs, Events" 
                />
            </Helmet>
            <header className="container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <a
                        className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-purple-800 bg-purple-100"
                        
                    >
                        Events rédactions
                    </a>
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="POLITIQUE DE CONFIDENTIALITE DE EVENTS"
                    >
                        POLITIQUE DE CONFIDENTIALITE DE EVENTS
                    </h1>
                    <div className="flex flex-col items-baseline sm:flex-row sm:justify-between" style={{marginTop: "2rem"}}>
                        <div className="nc-PostMeta2 mb-5 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
                            <div className="relative flex-shrink-0 flex items-center justify-center rounded-full w-8 h-8 sm:h-11 sm:w-11  flex-shrink-0">
                                <img
                                alt="Events"
                                loading="lazy"
                                decoding="async"
                                data-nimg={1}
                                className="inset-0  object-cover"
                                style={{ color: "transparent" }}
                                srcSet="../../assets/images/logo_events.png"
                                src="../../assets/images/logo_events.png"
                                />
                            </div>
                            <div className="ml-3">
                                <div className="flex items-center">
                                <a className="block font-semibold">
                                    Equipe Events
                                </a>
                                </div>
                                <div className="text-xs mt-[6px]">
                                    <span className="text-neutral-700 dark:text-neutral-300">
                                        Mai 2024
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                            <nav
                                className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 "
                                data-nc-id="SocialsList"
                            >
                                <Link
                                className="block"
                                to="https://www.facebook.com/profile.php?id=61558762937775"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Facebook"
                                >
                                <i className="lab la-facebook-square" />
                                </Link>
                                {/* <a
                                className="block"
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Twitter"
                                >
                                <i className="lab la-twitter" />
                                </a>
                                <a
                                className="block"
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Youtube"
                                >
                                <i className="lab la-youtube" />
                                </a> */}
                                <Link
                                className="block"
                                to="https://www.instagram.com/events.app225?igsh=YzljYTk1ODg3Zg=="
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Instagram"
                                >
                                <i className="lab la-instagram" />
                                </Link>
                                <Link
                                className="block"
                                to="https://wa.me/+2250717519518"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Whatsapp Support Events"
                                >
                                <i className="lab la-whatsapp" />
                                </Link>
                            </nav>
                        </div>
                    </div>
                    <div className="w-full border-b border-neutral-100 dark:border-neutral-800 mb-5" />
                </div>
            </header>
            <div className="nc-SingleContent container space-y-10 mt-5">
                <div className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark">
                <span className="text-neutral-700 dark:text-neutral-300">Dernière mise à jour de cette politique : 20 Mai 2024</span>

                <p>
                    <strong>Events</strong> accorde une grande importance à la protection de votre vie privée. <br />
                    <strong>Events</strong>, Société À Responsabilité Limitée Unipersonnelle <b>(« Nous »)</b> est responsable du traitement de vos données personnelles. <br /><br />
                    La présente politique de confidentialité (ci-après la <b>"Politique"</b>) décrit la manière dont <strong>Events</strong>  (ci-après <strong>"Events",</strong> <b>"nous",</b> <b>"notre"</b> ou <b>"nos"</b>) 
                    collecte et le type de données que nous recueillons, l’utilisation que nous en faisons et la façon dont nous les traitons, conservons et partageons éventuellement. <br /><br />
                    Ce document s’applique uniformément à tous les produits et services de <strong>Events</strong> (des applications mobiles iOS et Android <strong>Events</strong> dès lors que vous les téléchargez 
                    sur votre terminal mobile), ses partenaires (organisateurs d'événements) et autres agents. <br /><br />
                    Dans la présente Politique, les mots <b>“Personne concernée”</b> <b>“Vous”,</b> <b>“votre”</b> ou <b>“vos”</b> renvoient au client que vous êtes. <br /><br />
                    En utilisant l'Application, vous acceptez les termes de cette Politique. Si vous n'êtes 
                    pas d'accord avec ces termes, veuillez ne pas utiliser l'Application et ses autres services.
                </p>
                <h3>INFORMATIONS COLLECTEES</h3>
                <ol>
                    <li>
                        <strong>Informations collectées auprès des Utilisateurs</strong> 
                    </li>
                    <p>
                        Nous comprenons que la confidentialité en ligne est importante pour les utilisateurs 
                        de notre plateforme. Nous nous engageons à protéger votre vie privée et à 
                        respecter la confidentialité de vos informations personnelles. 
                        Afin de vous fournir nos services de manière efficace et personnalisée, nous collectons différentes catégories d'informations personnelles. 
                        <br /><br />
                        Lors de l'inscription à l'Application, nous collectons des informations personnelles 
                        essentielles pour créer et gérer votre compte utilisateur. Ces informations nous 
                        permettent de vous identifier, de sécuriser votre compte et de vous fournir les services 
                        demandés. Les informations collectées incluent : 
                        <ul>
                            <li>Nom et prénom</li>
                            <li>Adresse email</li>
                            <li>Numéro de téléphone etc.</li>
                            <li>Informations de paiement (cartes de crédit, Mobile Money, etc.)</li>
                        </ul>
                        En utilisant l'Application, nous recueillons également des informations sur votre 
                        activité afin d'améliorer votre expérience utilisateur, personnaliser les services et 
                        optimiser notre offre. Notamment votre Adresse IP, Détails matériels et logiciels, 
                        Historique des achats de billets. <br /><br />
                        Veuillez noter que nous ne collectons que des données qui nous aident à 
                        atteindre les objectifs définis dans cette politique de confidentialité. Nous ne 
                        collecterons pas de données supplémentaires sans vous en informer au préalable. <br /><br />
                        Lors de la réservation et de l'achat de tickets, nous collectons des informations 
                        supplémentaires pour traiter vos commandes, gérer les transactions et vous fournir 
                        un service client optimal. Les informations collectées lors des réservations de tickets incluent : 
                        <ul>
                            <li>Détails de l'événement réservé</li>
                            <li>Nombre de tickets réservés</li>
                            <li>Catégorie de tickets (VIP, standard, etc.)</li>
                            <li>Détails de paiement et de facturation</li>
                            <li>Préférences spécifiques (si applicable)</li>
                        </ul>
                    </p>
                    <li>
                        <strong>Informations collectées auprès des Partenaires (Organisateurs d'événements)</strong> 
                    </li>
                    <p>
                        Pour permettre aux organisateurs d'événements de gérer leurs événements et 
                        d'utiliser nos services, nous collectons des informations personnelles et 
                        professionnelles. Ces données sont nécessaires pour assurer une communication 
                        fluide, vérifier son statut et un soutien adéquat aux partenaires. Les informations collectées incluent : 
                        <ul>
                            <li>Nom de l'organisation au dépend</li>
                            <li>Nom et prénom du contact principal</li>
                            <li>Adresse email</li>
                            <li>Numéro de téléphone</li>
                            <li>Adresse de résidence</li>
                            <li>Informations d’identités</li>
                            <li>Données économiques, bancaires, financières et fiscales (pays de résidence, Relevé d’Identité Bancaire (RIB) etc.).</li>
                        </ul>
                        Nous recueillons également des informations spécifiques relatives aux événements 
                        organisés via notre plateforme. Ces données sont essentielles pour la gestion, la 
                        promotion et le suivi des événements. Les informations collectées comprennent : 
                        <ul>
                            <li>Détails de l'événement (titre, description, date, lieu)</li>
                            <li>Informations sur les billets (prix, catégories)</li>
                            <li>Informations sur les participants (nombre de billets vendus, statistiques de participation)</li>
                            <li>Consentement conforme aux droits de vente des organisateurs sur la plateforme d’application <strong>EVENTS</strong></li>
                        </ul>
                        Ces données peuvent être collectées selon les méthodes de remplissage via un 
                        formulaire ou de manière manuelle sous demande de l’équipe technique de <strong>Events. </strong>
                        Nous ne traitons jamais de données relatives aux origines raciales ou ethniques, 
                        opinions politiques, à la religion, aux convictions philosophiques ou à l'appartenance 
                        syndicale, et aux données génétiques. <br /><br />
                        Les informations personnelles que nous détenons à votre sujet sont directement mises à notre disposition lorsque vous : 
                        <ul>
                            <li>Inscrivez-vous pour un Compte Client, Organisateur ou Agent ;</li>
                            <li>Utiliser l'un de nos services ;</li>
                            <li>Contactez notre équipe de support client ;</li>
                            <li>Remplissez nos formulaires en ligne ;</li>
                            <li>Nous contactez.</li>
                        </ul>
                    </p>
                </ol>
                <h3>UTILISATION DES INFORMATIONS</h3>
                <ol>
                    <li>
                        <strong>Utilisation des Informations des Utilisateurs</strong> 
                    </li>
                    <p>
                        Les informations collectées auprès des Utilisateurs sont utilisées pour divers objectifs 
                        afin de garantir une expérience utilisateur optimale et sécurisée. Plus précisément, nous utilisons ces informations pour : 
                        <ul>
                            <li>
                                <b>Fournir et améliorer nos services :</b> Utiliser les données pour assurer le bon 
                                fonctionnement de l'Application, améliorer ses fonctionnalités et développer 
                                de nouveaux services. Cela inclut l'analyse des données d'utilisation pour 
                                identifier les tendances et les préférences des utilisateurs.
                            </li>
                            <li>
                                <b>Gérer les réservations et achats de billets :</b> Traiter et gérer vos réservations et 
                                achats de billets pour les événements, incluant la vérification des informations 
                                de paiement, l'émission des billets électroniques, et le suivi des commandes.
                            </li>
                            <li>
                                <b>Communiquer avec les Utilisateurs :</b> Envoyer des confirmations de réservation, 
                                des rappels d'événements, des notifications de mise à jour, et toute autre 
                                communication nécessaire au bon déroulement de votre expérience avec 
                                l'Application. Nous pouvons également vous informer de promotions spéciales, 
                                d'offres ou d'événements susceptibles de vous intéresser, sous réserve de votre consentement.
                            </li>
                            <li>
                                <b>Personnaliser l'expérience utilisateur :</b> Adapter notre offre et nos 
                                recommandations en fonction de vos préférences et de votre historique 
                                d'achat. Cela inclut la personnalisation du contenu affiché sur l'Application et 
                                l'envoi de suggestions d'événements en fonction de vos intérêts.
                            </li>
                            <li>
                                <b>Traiter les paiements et prévenir la fraude :</b> Vérifier et sécuriser les transactions 
                                effectuées sur l'Application pour prévenir les activités frauduleuses et garantir 
                                la sécurité de vos informations financières. Nous utilisons des mesures de 
                                sécurité avancées pour protéger vos informations de paiement.
                            </li>
                            <li>
                                <b>Respecter nos obligations légales et réglementaires :</b> Conserver et utiliser vos 
                                informations personnelles conformément aux lois et réglementations 
                                applicables. Cela peut inclure la coopération avec les autorités compétentes 
                                en cas de demandes légales ou la conformité avec les exigences de 
                                conservation de données.
                            </li>
                            <li>
                                <b>Améliorer la sécurité de l'Application :</b> Surveiller et analyser les activités 
                                suspectes ou malveillantes pour protéger les utilisateurs contre les menaces de 
                                sécurité. Nous déployons des mesures de sécurité techniques et 
                                organisationnelles pour protéger vos données personnelles contre tout accès 
                                non autorisé.
                            </li>
                            <li>
                                <b>Fournir un support client efficace :</b> Utiliser vos informations pour répondre à vos 
                                demandes d'assistance, résoudre les problèmes techniques et améliorer le 
                                service client. Nous pouvons également vous contacter pour obtenir des 
                                retours d'expérience afin d'améliorer nos services.
                            </li>
                        </ul>
                        Nous conservons vos Données personnelles dans un format identifiable pendant la 
                        durée minimale nécessaire pour remplir nos obligations légales ou réglementaires, et 
                        pour nos besoins opérationnels. Nous pouvons être amenés à conserver vos Données 
                        personnelles pendant des périodes plus longues pour défendre nos intérêts 
                        commerciaux légitimes, sans toutefois contrevenir à la loi.
                    </p>
                    <li>
                        <strong>Utilisation des Informations des Partenaires</strong> 
                    </li>
                    <p>
                        Les informations collectées auprès des Partenaires (organisateurs d'événements) 
                        sont essentielles pour garantir une gestion efficace et une promotion réussie des 
                        événements. Voici comment nous utilisons ces informations : 
                        <ul>
                            <li>
                                <b>Gérer et promouvoir les événements :</b> Utiliser les informations fournies par les 
                                organisateurs pour planifier, gérer et promouvoir leurs événements sur notre 
                                plateforme. Cela inclut la création de pages d'événements, la mise en place 
                                de campagnes marketing et la diffusion des événements auprès de notre 
                                base d'utilisateurs.
                            </li>
                            <li>
                                <b>Faciliter la vente de billets et le suivi des transactions :</b> Assurer un processus de 
                                vente de billets fluide et efficace, en suivant les transactions en temps réel et 
                                en fournissant aux organisateurs des outils pour gérer leurs ventes et accéder 
                                aux données des participants. Nous offrons des rapports détaillés sur les ventes, 
                                les types de billets vendus et les statistiques de participation.
                            </li>
                            <li>
                                <b>Communiquer avec les organisateurs :</b> Maintenir une communication 
                                constante avec les organisateurs pour les informer sur l'état de leurs 
                                événements, fournir des mises à jour importantes, et offrir un support technique. 
                                Nous utilisons ces informations pour répondre aux demandes de support, 
                                résoudre les problèmes techniques et fournir des conseils sur l'optimisation des 
                                ventes de billets.
                            </li>
                            <li>
                                <b>Effectuer les paiements aux organisateurs :</b> Utiliser les informations bancaires 
                                pour traiter les paiements des recettes générées par les ventes de billets. Nous 
                                garantissons la précision et la sécurité des transactions financières, tout en 
                                respectant les délais convenus pour les versements.
                            </li>
                            <li>
                                <b>Respecter nos obligations légales et réglementaires :</b> Conserver et utiliser les 
                                informations des partenaires en conformité avec les lois et réglementations 
                                applicables. Cela inclut la coopération avec les autorités compétentes en cas 
                                de demandes légales, la conformité avec les exigences fiscales et la 
                                prévention des fraudes.
                            </li>
                            <li>
                                <b>Optimiser l'expérience des organisateurs :</b> Analyser les données pour améliorer 
                                les outils et services proposés aux organisateurs, tels que les fonctionnalités de 
                                gestion d'événements, les outils marketing, et les solutions de billetterie. Nous 
                                recueillons également des retours des organisateurs pour ajuster nos services 
                                en fonction de leurs besoins.
                            </li>
                            <li>
                                <b>Protéger la sécurité des informations :</b> Mettre en œuvre des mesures de 
                                sécurité avancées pour protéger les informations sensibles des organisateurs 
                                contre tout accès non autorisé, divulgation ou destruction. Nous surveillons 
                                activement les menaces potentielles et réagissons rapidement pour protéger 
                                les données de nos partenaires.
                            </li>
                        </ul>
                    </p>
                </ol>

                <h3>PARTAGE DES INFORMATIONS</h3>
                <ol>
                    <li>
                        <strong>Partage avec des Tiers</strong> 
                    </li>
                    <p>
                        Nous pouvons divulguer à tout membre de notre organisation les données utilisateur 
                        dont il a raisonnablement besoin pour remplir les objectifs énoncés dans la présente politique. <br /><br />
                        <strong>Events</strong> ne partage des informations personnelles avec des tiers externes que dans les 
                        circonstances limitées. Nous nous engageons à protéger la confidentialité de vos 
                        informations personnelles et à les partager uniquement lorsque cela est nécessaire 
                        et approprié. Nous pouvons partager les informations collectées avec des tiers dans les cas suivants : 
                        <ul>
                            <li>
                                <b>Avec les partenaires (organisateurs d'événements) pour la gestion et la 
                                promotion des événements :</b> Nous partageons des informations pertinentes avec les organisateurs d'événements pour les aider à gérer et à promouvoir 
                                leurs événements. Cela peut inclure des informations sur les participants, les 
                                ventes de billets, et les préférences des utilisateurs afin d'optimiser la 
                                planification et la communication autour de l'événement.
                            </li>
                            <li>
                                <b>Avec les prestataires de services (paiement, marketing, support client) pour 
                                faciliter nos services :</b> Nous collaborons avec des prestataires de services tiers 
                                pour assurer le bon fonctionnement de l'Application et offrir des services de 
                                qualité. Ces prestataires peuvent inclure : 
                                <ul className='liStyle-e'>
                                    <li>
                                        <b>Fournisseurs de services de paiement :</b> Pour traiter les transactions 
                                        financières en toute sécurité.
                                    </li>
                                    <li>
                                        <b>Agences de marketing :</b> Pour mener des campagnes publicitaires et 
                                        promouvoir les événements.
                                    </li>
                                    <li>
                                        <b>Fournisseurs de support client :</b> Pour offrir une assistance technique et 
                                        répondre aux demandes des utilisateurs.
                                    </li>
                                    <li>
                                        <b>Fournisseurs d'hébergement et de maintenance technique :</b> Pour garantir la disponibilité et la performance de l'Application.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>En cas de fusion, acquisition ou vente d'actifs :</b> Si nous sommes impliqués dans 
                                une fusion, une acquisition ou une vente d'actifs, vos informations personnelles 
                                peuvent être transférées à la nouvelle entité. Nous veillerons à ce que la 
                                nouvelle entité respecte les termes de cette Politique de Confidentialité ou 
                                vous informera de tout changement significatif.
                            </li>
                            <li>
                                <b>Pour se conformer à des obligations légales, réglementaires ou judiciaires :</b> Nous pouvons divulguer vos informations personnelles si la loi l'exige ou si nous 
                                croyons de bonne foi que cette divulgation est nécessaire pour : 
                                <ul className='liStyle-e'>
                                    <li>
                                        <b>
                                            Répondre à des demandes légitimes des autorités publiques, y compris 
                                            pour répondre à des exigences de sécurité nationale ou d'application de la loi.
                                        </b>
                                    </li>
                                    <li>
                                        <b>
                                            Se conformer à des obligations légales, réglementaires ou judiciaires.
                                        </b>
                                    </li>
                                    <li>
                                        <b>
                                            Protéger nos droits, notre propriété ou notre sécurité, ainsi que ceux de nos utilisateurs ou du public.
                                        </b>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Pour protéger les droits et la sécurité :</b> Nous pouvons partager des informations 
                                si nous pensons qu'il est nécessaire d'enquêter, de prévenir ou de prendre des 
                                mesures concernant des activités illégales, des fraudes suspectées, des 
                                situations impliquant des menaces potentielles à la sécurité de toute personne, 
                                des violations de nos Conditions Générales d'Utilisation, ou dans d'autres 
                                circonstances permises par la loi.
                            </li>
                            <li>
                                <b>Avec votre consentement :</b> Dans certains cas, nous pouvons partager vos 
                                informations personnelles avec des tiers lorsque vous avez donné votre 
                                consentement explicite pour ce partage. Cela peut inclure des situations où 
                                vous choisissez de lier votre compte avec des services tiers ou de partager vos 
                                informations sur des plateformes de réseaux sociaux.
                            </li>
                            <li>
                                <b>Aux partenaires bancaires, autorités financières, judiciaires, de régulation, 
                                agences étatiques, organismes publics :</b> Ces partages de données sont 
                                réalisés à leur demande et dans la limite de ce qui est permis par la 
                                réglementation ivoirienne. Ainsi, vos données peuvent être communiquées et 
                                partagées aux Autorités publiques ivoiriennes, habilitées dans le cadre de
                                l'exercice de leurs missions (PLCC, CENTIF, la Commission Bancaire de 
                                l’UEMOA, ECOBANK, BGFIBANK, BDA, le Pôle Pénal Économique et Financier, 
                                La Police Judiciaire, la Police Économique). Les opérateurs de 
                                télécommunications Orange, Moov et MTN, Wave, l’UEMOA, la BCEAO, la 
                                DGTCP, la DGI.
                            </li>
                            <li>
                                Ces partenaires bancaires, autorités financières, judiciaires, de régulation, 
                                agences étatiques, organismes publics sont situés en République de Côte d’Ivoire.
                            </li>
                        </ul>
                    </p>
                    Nous prenons des mesures appropriées pour garantir que les tiers avec lesquels nous 
                    partageons vos informations respectent la confidentialité de vos données et les 
                    utilisent uniquement dans les limites permises par cette Politique de Confidentialité.
                    <li>
                        <strong>Protection des Données</strong> 
                    </li>
                    <p>
                        Nous nous engageons à protéger les informations personnelles de nos utilisateurs et 
                        partenaires. Nous utilisons des mesures de sécurité techniques et organisationnelles 
                        appropriées pour protéger les informations contre tout accès non autorisé, 
                        divulgation, altération ou destruction. <br /><br />
                        Toutes les données stockées dans notre système sont bien sécurisées et ne sont 
                        accessibles qu'à nos employés. Nos employés sont liés par des accords de 
                        confidentialité stricts et une violation de cet accord entraînera le licenciement de l'employé. <br /><br />
                        Bien que nous prenions toutes les précautions raisonnables pour nous assurer que nos 
                        données d'utilisateur soient sécurisées et que les utilisateurs soient protégés, il reste 
                        toujours un risque de préjudice. L'Internet dans son ensemble peut parfois être peu 
                        sûr et nous ne sommes donc pas en mesure de garantir la sécurité des données des 
                        utilisateurs au-delà de ce qui est raisonnablement pratique.
                    </p>
                </ol>
                <h3>DROITS DES UTILISATEURS, PARTENAIRES ET AGENTS</h3>
                <ol>
                    <li>
                        <strong>Accès et Rectification</strong> 
                    </li>
                    <p>
                        Conformément à la réglementation applicable, notamment les articles 28 et suivants de la <i>Loi n° 2013-450 du 19 juin 2013 </i> 
                        relative à la protection des données à caractère 
                        personnel, vous disposez de différents droits, notamment vous avez le droit 
                        d'accéder aux informations personnelles que nous détenons à votre sujet et de 
                        demander leur rectification en cas d'erreur ainsi qu’une copie de ces données personnelles. <br /><br />
                        Vous pouvez, en justifiant de votre identité, exiger de <strong>EVENTS</strong> que soient, selon les 
                        cas, rectifiées, complétées, mises à jour, verrouillées ou supprimées vos données à 
                        caractère personnel la concernant, qui sont inexactes, incomplètes, équivoques, 
                        périmées, ou dont la collecte, l’utilisation, la communication ou la conservation est interdite. <br /><br />
                        Vous pouvez exercer ce droit en nous contactant à l'adresse suivante : <Link className="font-medium text-primary-6000" to="mailto:contact@app-events.com">contact@app-events.com.</Link>
                    </p>
                    <li>
                        <strong>Opposition et Suppression</strong> 
                    </li>
                    <p>
                        Vous pouvez également vous opposer au traitement de vos informations 
                        personnelles ou demander leur suppression, sous réserve des obligations légales et 
                        réglementaires qui nous incombent. <br /><br />
                        Vous pouvez demander la limitation de traitement de vos données personnelles. 
                        Pour les Clients, sont concernés les traitements qui ne sont pas essentiels à l’exécution 
                        du contrat prévu par les conditions générales d’utilisation. <br /><br />
                        Vous pouvez vous opposer au traitement de vos données personnelles, pour des 
                        motifs liés à votre situation particulière. Vous disposez du droit absolu de vous opposer 
                        au traitement de vos données personnelles à des fins de prospection commerciale, 
                        y compris le profilage lié à cette prospection. <br /><br />
                        Pour exercer ce droit, veuillez nous contacter à l'adresse suivante : <Link className="font-medium text-primary-6000" to="mailto:contact@app-events.com">contact@app-events.com.</Link>
                    </p>
                </ol>
                
                <h3>CONSERVATION DES DONNEES</h3>
                <p>
                    Nous conservons les informations personnelles aussi longtemps que nécessaire pour 
                    fournir nos services, respecter nos obligations légales et résoudre les litiges. Les critères 
                    de détermination de la durée de conservation incluent la nature des informations, la 
                    finalité de leur collecte et les exigences légales applicables.
                </p>
                
                <h3>MODIFICATIONS DE LA POLITIQUE</h3>
                <p>
                    Nous nous réservons le droit de modifier cette Politique à tout moment. Les 
                    modifications seront publiées sur notre application et site internet et entreront en 
                    vigueur dès leur publication. Nous encourageons les utilisateurs et partenaires à 
                    consulter régulièrement cette page pour être informés des mises à jour.
                </p>

                <h3>CONTACT</h3>
                <p>
                    Pour toute question ou demande concernant cette Politique de Confidentialité, 
                    veuillez nous contacter à l'adresse suivante : <br />
                    <strong>EVENTS</strong><br />
                    <b><Link className="font-medium text-primary-6000" to="mailto:contact@app-events.com">contact@app-events.com.</Link></b><br />
                    <b>(+225) 05 00 35 5775</b>
                </p>
                </div>
               
            </div>
        </div>
    )
}
